import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import STATES from "../enums/STATES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import Menu from "@material-ui/core/Menu";
import Paper from "@material-ui/core/Paper";
import DownloadMaterialsDialog from "./DownloadMaterialsDialog";

export default function DownloadMaterialsCard(props) {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const [packageTypes, setPackageTypes] = useState([
    PACKAGE_TYPES.FORMS.key,
    PACKAGE_TYPES.LOSS_COST.key,
    PACKAGE_TYPES.RULES.key,
    PACKAGE_TYPES.RATING_INFO.key,
  ]);

  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function addState(state) {
    states.push(state);
    setStates(states);
    setAnchorEl(null);
  }

  function deleteState(state) {
    const tempStates = states.filter((aState) => aState !== state);
    setStates(tempStates);
  }

  function getSelectedStateKeys() {
    const results = states.map((state) => STATES[state].key);
    return results;
  }

  function togglePackageTypes(packageTypeKey) {
    if (packageTypes.includes(packageTypeKey)) {
      const tempPackageTypes = packageTypes.filter(
        (key) => packageTypeKey !== key
      );
      setPackageTypes(tempPackageTypes);
    } else {
      setPackageTypes(packageTypes.concat(packageTypeKey));
    }
  }

  function getSubheader() {
    let title = packageTypes.map(
      (packageType) => PACKAGE_TYPES[packageType].title
    );
    title = title.sort();
    title = title.join(", ");
    //eslint-disable-next-line no-useless-concat
    title = title.replace(/,([^,]*)$/, " and " + "$1");
    return title;
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`Download Latest ${props.lineKey} Program Materials`}
            subheader={getSubheader()}
          />
          <CardContent>
            <Paper className={classes.paper} elevation={0}>
              {states.map((stateKey) => (
                <Chip
                  key={stateKey}
                  variant="outlined"
                  // color="primary"
                  avatar={<Avatar>{STATES[stateKey].key}</Avatar>}
                  label={STATES[stateKey].title}
                  onDelete={() => deleteState(stateKey)}
                  className={classes.chip}
                  data-test={`chip${STATES[stateKey].key}`}
                />
              ))}
              {states.length === 0 && <Typography>No state added</Typography>}
            </Paper>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {Object.keys(STATES).map((stateKey) => (
                <MenuItem
                  onClick={() => addState(stateKey)}
                  key={stateKey}
                  disabled={states.includes(stateKey)}
                  data-test={`select${STATES[stateKey].key}`}
                >
                  {STATES[stateKey].title}
                </MenuItem>
              ))}
            </Menu>
          </CardContent>
          <CardActions>
            <Grid container spacing={1} justify="space-between">
              <Grid item container spacing={1} xs={9}>
                <Grid item>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="primary"
                    variant="outlined"
                    data-test="addState"
                  >
                    Add State
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test="downloadLatest"
                    onClick={() => setShowDialog(true)}
                    disabled={states.length === 0 || packageTypes.length === 0}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={3} container justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "less" : "more"}
                  </Button>
                </Grid>
              </Grid>
              {/* <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={() => setExpanded(!expanded)}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton> */}
            </Grid>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(PACKAGE_TYPES.FORMS.key)}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.FORMS.key)
                        }
                        value={packageTypes.includes(PACKAGE_TYPES.FORMS.key)}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.FORMS.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(
                          PACKAGE_TYPES.LOSS_COST.key
                        )}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.LOSS_COST.key)
                        }
                        value={packageTypes.includes(
                          PACKAGE_TYPES.LOSS_COST.key
                        )}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.LOSS_COST.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(PACKAGE_TYPES.RULES.key)}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.RULES.key)
                        }
                        value={packageTypes.includes(PACKAGE_TYPES.RULES.key)}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.RULES.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(
                          PACKAGE_TYPES.RATING_INFO.key
                        )}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.RATING_INFO.key)
                        }
                        value={packageTypes.includes(
                          PACKAGE_TYPES.RATING_INFO.key
                        )}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.RATING_INFO.title}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
        {showDialog && (
          <DownloadMaterialsDialog
            lineKey={props.lineKey}
            states={getSelectedStateKeys()}
            packageTypes={packageTypes}
            handleClose={() => setShowDialog(false)}
          ></DownloadMaterialsDialog>
        )}
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));
