/**
 * @description The enumeration of the Address types
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const ADDRESS_TYPES = {
  /**
   * @constant {String}
   * @description The mailing address
   * @since 1.0.0
   */
  MAILING: {
    key: "mailing",
    title: "Mailing Address",
    description: "The mailing address",
  },
  /**
   * @constant {String}
   * @description The billing address
   * @since 1.0.0
   */
  BILLING: {
    key: "billing",
    title: "Billing Address",
    description: "The Billing address",
  },
};

export default ADDRESS_TYPES;
