/**
 * @description A generic Risk awareness card for ArcGIS iframe content
 * @module components/GenericRiskCard
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";

function AddressCard(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Fade in={true} timeout={1000}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={props.title} />
          <CardContent className={classes.map}>
            <iframe
              width="100%"
              height="350"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              title={props.title}
              src={props.src}
              allowFullScreen
            ></iframe>
          </CardContent>
          {/* <CardContent>This is some content</CardContent> */}
          <CardActions className={classes.cardActions}>
            {props.callToActionText ? (
              <Button
                size="small"
                color="primary"
                href={props.callToActionHref}
              >
                {props.callToActionText}
              </Button>
            ) : (
              <Button size="small" color="secondary" href={props.src}>
                Open
              </Button>
            )}
          </CardActions>
        </Card>
      </Fade>
    </React.Fragment>
  );
}

const styles = (theme) => ({
  grid: {
    flexGrow: 1,
  },
  map: {
    padding: 0,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  line1: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  line2: {
    fontSize: theme.typography.pxToRem(16),
  },
  line3: {
    fontSize: theme.typography.pxToRem(16),
  },
  formattedAddress: {
    fontSize: theme.typography.pxToRem(16),
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    fontSize: 14,
  },
});

export default withStyles(styles, { withTheme: true })(AddressCard);
