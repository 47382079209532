import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UpdatesIcon from "@material-ui/icons/Update";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HelpIcon from "@material-ui/icons/Help";

function OrgTabsSection(props) {
  const { classes, materialsTab, updatesTab, helpTab } = props;

  const [selectedTab, setTab] = useState(0);

  function handleChange(event, newSelectedTab) {
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Program Materials" icon={<FileCopyIcon />} />
          <Tab label="Recent Changes" icon={<UpdatesIcon />} />
          {/* <Tab label="Support" icon={<HelpIcon />} /> */}
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{materialsTab}</React.Fragment>}
      {selectedTab === 1 && <React.Fragment>{updatesTab}</React.Fragment>}
      {/* {selectedTab === 2 && <React.Fragment>{addressesTab}</React.Fragment>} */}
    </React.Fragment>
  );
}

const styles = (theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  tab: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "red",
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(OrgTabsSection);
