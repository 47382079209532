import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import * as MdsApi from "../apis/MdsApi";
import { CircularProgress, Divider, LinearProgress } from "@material-ui/core";

export default function BasicTable(props) {
  const classes = useStyles();

  const [records, setRecords] = useState([]);
  const [showIndividualStates, setShowIndividualStates] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRecords();
  }, []);

  async function loadRecords() {
    setLoading(true);
    const tempRecords = await MdsApi.getBucketFilesDataQuality(
      props.bucketId,
      props.bucketFileId
    );

    if (tempRecords) setRecords(tempRecords);

    setLoading(false);
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress />}

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell>State</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Quarter</TableCell>
              <TableCell>Line</TableCell>
              <TableCell>Total Premium</TableCell>
              <TableCell>Error Premium</TableCell>
              <TableCell>Error Premium %</TableCell>
              <TableCell>Min Premium to be corrected</TableCell>
              <TableCell>Total Paid Loss</TableCell>
              <TableCell>Error Paid Loss</TableCell>
              <TableCell>Error Paid Loss %</TableCell>
              {/* <TableCell>Min Paid Loss %</TableCell> */}
              <TableCell>Min Paid Loss to be corrected</TableCell>
              <TableCell>Total OS Loss</TableCell>
              <TableCell>Error OS Loss</TableCell>
              <TableCell>Error OS Loss %</TableCell>
              <TableCell>Min OS Loss to be corrected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && records?.length === 0 && (
              <React.Fragment>
                <TableRow>
                  <TableCell scope="row" colSpan={17}>
                    {props.status === 22 && (
                      <Typography variant="body2" align="center">
                        <CircularProgress size={15}></CircularProgress>
                        {` Processing stats. Open and close to refresh`}
                      </Typography>
                    )}
                    {(props.status === 23 || !props.status) && (
                      <Typography variant="body2" align="center">
                        Nothing to display
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            )}
            {records.map((row) => {
              if (row?.state?.match(/All States/i)) {
                if (showIndividualStates) {
                  return null;
                }
              } else {
                if (!showIndividualStates) {
                  return null;
                }
              }

              return (
                <TableRow
                  key={`${row.year}-${row.quarter}-${row.lineName}-${row.state}-${row.aaisCompanyNumber}`}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      className={classes.allStates}
                      color="primary"
                      onClick={() =>
                        setShowIndividualStates(!showIndividualStates)
                      }
                    >
                      {row.state}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{row.year}</TableCell>
                  <TableCell align="right">{row.quarter}</TableCell>
                  <TableCell>{row.lineName}</TableCell>
                  <TableCell align="right">
                    {row.totalPremium?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorPremium?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorPremiumPercent?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.minPremiumToCorrect?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.totalPaidLoss?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorPaidLoss?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorPaidLossPercent?.toLocaleString()}
                  </TableCell>
                  {/* <TableCell align="right">
                    {row.minPremiumToCorrect?.toLocaleString()}
                  </TableCell> */}
                  <TableCell align="right">
                    {row.minPaidLossToCorrect?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.totalOutstandingLoss?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorOutstandingLoss?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.errorOutstandingLossPercent?.toLocaleString()}
                  </TableCell>
                  <TableCell align="right">
                    {row.minOutstandingLossToCorrect?.toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  header: {
    backgroundColor: theme.palette.grey[100],
  },
  allStates: {
    cursor: "pointer",
  },
}));
