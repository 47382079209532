/**
 * @description This is the common util module that is used
 * throught the application.
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module util/commonUtil
 */

/**
 * @description Takes an array of items and turn them into a list of strings.
 * This also cleans up the input and removes empty string, nulls and undefined
 * @since 1.0.0
 * @param {Array} data strings
 * @returns a concatination of trimmed strings with the nulls, undefined filtered out
 */
export function filterAndJoinResults(data) {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return null;
  }

  //filter out all the null values
  const filteredData = data.filter(
    item => item && typeof item === "string" && item.trim()
  );

  const trimmedData = filteredData.map(item => item.trim());

  //merge all the results
  const mergedData = trimmedData.join();

  //return null if data is empty
  if (!mergedData || mergedData.length === 0) return null;

  return mergedData;
}

/**
 * @description get an enum based on its object key
 * @since 1.0.0
 * @param {object} enumType the enum that you want to search
 * @param {*} key the key you want to map back to
 * @example
 * getEnumFromKey(CONTACT_TYPES, "aaisDirectCompanyAdmin");
 * //this returns CONTACT_TYPES.AAIS_DIRECT_COMPANY_ADMIN
 */
export function getEnumFromKey(enumType, key) {
  const enumNames = Object.keys(enumType ? enumType : {});
  for (const enumName of enumNames) {
    if (enumType[enumName].key === key) {
      return enumType[enumName];
    }
  }
  return null;
}
