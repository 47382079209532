import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { getEnumFromKey } from "../util/commonUtil";
import CONTACT_TYPES from "../enums/CONTACT_TYPES";
import * as userUtil from "../util/userUtil";

function RemoveUserConfirmation(props) {
  const { classes } = props;

  function getAuditItems() {
    if (
      !props.audit ||
      !props.audit.contactTypes ||
      props.audit.contactTypes.length <= 0
    ) {
      return null;
    }

    const contactItems = props.audit.contactTypes.map(contactType => {
      const contactTypeEnum = getEnumFromKey(CONTACT_TYPES, contactType);
      if (contactTypeEnum) {
        return <li key={contactTypeEnum.key}>{contactTypeEnum.title}</li>;
      }
      return null;
    });

    return contactItems;
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      aria-label="Remove User Confirmation Dialog"
      className={classes.root}
    >
      <DialogTitle>
        Remove {userUtil.getDisplayName(props.user)} from {props.orgName}?
      </DialogTitle>
      <DialogContent>
        All of {userUtil.getDisplayName(props.user)}&apos;s roles will be removed
      </DialogContent>
      <DialogContent>{getAuditItems()}</DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => props.handleDelete(props.user)}
          color="primary"
          variant="contained"
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
});

export default withStyles(styles, { withTheme: true })(RemoveUserConfirmation);
