import React, { useState, useEffect } from "react";
import BrowsePicker from "../components/BrowsePicker";
import BrowseResults from "../components/BrowseResults";
import { Grid, Container, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import * as AssetApi from "../apis/AssetApi";
import STATES from "../enums/STATES";
import DownloadMaterialsDialogV2 from "../components/DownloadMaterialsDialogV2";

export default function BrowseScreen(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [line, setLine] = useState("");
  const [lineClass, setLineClass] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Externally Approved");
  const [packageType, setPackageType] = useState("");
  const [term, setTerm] = useState("");
  const [states, setStates] = useState([]);
  const [supportingDocsType, setSupportingDocsType] = useState("");
  const [scrollId, setScrollId] = useState();
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    performSearch();
  }, [line, status, packageType, term, states, supportingDocsType, lineClass]);

  async function performSearch() {
    setLoading(true);
    setResults(null);
    if (!line || states.length === 0 || !packageType) {
      setLoading(false);
      return;
    }
    //const tempResults = await AssetApi.search("business owners");
    const tempResults = await AssetApi.search(term, {
      productLine: line,
      status_s: status,
      packageType_s: packageType,
      states: states?.map((name) => STATES[name].key),
      imgClass_s: lineClass,
      documentType_s_query: supportingDocsType,
    });
    setScrollId(tempResults._scroll_id); //store the scollId. it can change
    setResults(tempResults);
    setLoading(false);
  }

  async function loadMore() {
    if (!scrollId) return;

    const tempResults = await AssetApi.scroll(scrollId);
    if (!tempResults?.hits?.hits?.length) {
      console.warn("tried to scroll, skipping", tempResults);
      return;
    }

    setScrollId(tempResults._scroll_id); //store the scollId. it can change
    results.hits.hits = results.hits.hits.concat(tempResults.hits.hits);
    setResults(Object.assign({}, results));
  }

  function clearAll() {
    setLine("");
    setPackageType("");
    setTerm("");
    setStatus("Externally Approved");
    setLineClass("");
    setSupportingDocsType("");
    setScrollId();
    setStates([]);
  }

  function getBrowserPicker() {
    return (
      <BrowsePicker
        line={line}
        setLine={setLine}
        lineClass={lineClass}
        setLineClass={setLineClass}
        status={status}
        setStatus={setStatus}
        packageType={packageType}
        setPackageType={setPackageType}
        term={term}
        setTerm={setTerm}
        states={states}
        setStates={setStates}
        supportingDocsType={supportingDocsType}
        setSupportingDocsType={setSupportingDocsType}
        clearAll={clearAll}
      ></BrowsePicker>
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth={false} className={classes.container}>
        <Grid container spacing={1} alignItems="stretch">
          <Hidden smDown>
            <Grid item md={3} lg={2}>
              {getBrowserPicker()}
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9} lg={10}>
            <BrowseResults
              setDrawerOpen={setDrawerOpen}
              results={results}
              line={line}
              loading={loading}
              loadMore={loadMore}
              download={() => setShowDialog(true)}
            ></BrowseResults>
          </Grid>
        </Grid>
      </Container>
      {drawerOpen && (
        <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          <div className={classes.drawer}>{getBrowserPicker()}</div>
        </Drawer>
      )}
      {showDialog && (
        <DownloadMaterialsDialogV2
          lineKey={line}
          states={states?.map((name) => STATES[name].key)}
          packageTypes={[packageType]}
          status_s={status}
          imgClass_s={lineClass}
          documentType_s_query={supportingDocsType}
          searchTerm={term}
          handleClose={() => setShowDialog(false)}
        ></DownloadMaterialsDialogV2>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    height: "100%",
  },
  drawer: {
    maxWidth: 280,
  },
}));
