import React, { useState } from "react";
import {
  Container,
  Grid,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  CardContent,
  Card,
  CardHeader,
  Hidden,
  CardActions,
  Divider,
  Avatar,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import StatesIcon from "@material-ui/icons/Public";
import ProductsIcon from "@material-ui/icons/Category";
// import ProductsIcon from "@material-ui/icons/FilterNone";
import AAISPDFViewerDialog from "../components/AAISPDFViewerDialog";
import NotCurrent from "@material-ui/icons/NotInterested";
import CurrentIcon from "@material-ui/icons/Check";
import FormIcon from "@material-ui/icons/ListAlt";
import RuleIcon from "@material-ui/icons/Gavel";
import RatingIcon from "@material-ui/icons/StarRate";
import LossCostIcon from "@material-ui/icons/AttachMoney";
import SupportingDocIcon from "@material-ui/icons/AttachFile";
import Collapse from "@material-ui/core/Collapse";
import LINES from "../enums/LINES";
import STATES from "../enums/STATES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function BrowseResults(props) {
  const classes = useStyles();
  const [previewItem, setPreviewItem] = useState();
  const [expandedCard, setExpandedCard] = useState();
  const [moreLine, setMoreLine] = useState(props.line);

  function getItems() {
    if (!props.results || !props.results.hits || !props.results.hits.hits)
      return;

    return props.results.hits.hits.map((item, i) => {
      return (
        <div className={classes.item} key={item._id}>
          <Card variant="outlined" className={classes.card}>
            <CardHeader
              title={
                <Typography
                  className={classes.title}
                  color="primary"
                  onClick={() => {
                    setPreviewItem(item._source);
                  }}
                >
                  {item._source.title_s}
                </Typography>
              }
              subheader={getFormInfo(item, i)}
              className={classes.cardHeader}
            ></CardHeader>
            {item?.highlight?.text && (
              <CardContent className={classes.cardContent}>
                <div
                  className={classes.highlight}
                  variant="body1"
                  dangerouslySetInnerHTML={getHighlight(item)}
                ></div>
              </CardContent>
            )}
            <CardActions className={classes.cardActions}>
              <Tooltip
                title="Product Lines"
                PopperProps={{
                  "data-test": `browseResults-item-${i}-line-tooltip`,
                }}
                placement="top"
              >
                <Badge
                  badgeContent={getProductLineCount(item)}
                  data-test={`browseResults-item-${i}-line-badge`}
                  showZero
                >
                  <ProductsIcon
                    color="disabled"
                    data-test={`browseResults-item-${i}-line-icon`}
                  />
                </Badge>
              </Tooltip>
              <Tooltip
                title={`${props.line} states`}
                PopperProps={{
                  "data-test": `browseResults-item-${i}-state-tooltip`,
                }}
                placement="top"
              >
                <Badge
                  badgeContent={getStateCount(item)}
                  data-test={`browseResults-item-${i}-state-badge`}
                  showZero
                >
                  <StatesIcon
                    color="disabled"
                    data-test={`browseResults-item-${i}-state-icon`}
                  />
                </Badge>
              </Tooltip>
              {getTypeIcon(item, i)}
              {getStatusIcon(item, i)}
              <Button
                color="primary"
                size="small"
                className={classes.expandButton}
                onClick={() => toggleExpanded(item)}
              >
                {item._id === expandedCard ? "less" : "more"}
              </Button>
            </CardActions>
            {expandedCard && item._id === expandedCard && (
              <Collapse in={true} timeout="auto" unmountOnExit>
                <CardContent>
                  <Divider />
                  <Typography variant="subtitle1" align="center">
                    Product Lines
                  </Typography>
                  <Paper className={classes.chipsPaper} elevation={0}>
                    {getProductLinesForItem(item, i)}
                  </Paper>
                  <Divider className={classes.divider} />
                  <Typography variant="subtitle1" align="center">
                    States
                  </Typography>
                  <Paper className={classes.chipsPaper} elevation={0}>
                    {getStatesForLine(item)}
                  </Paper>
                </CardContent>
              </Collapse>
            )}
          </Card>
        </div>
      );
    });
  }

  function convertMsToSeconds(time) {
    if (Number.isNaN(time)) return "Error";

    return (time / 1000).toFixed(2);
  }

  function getLineToolTip(item, line) {
    const lineName = LINES[line] ? LINES[line].title : "Line Unknown";
    if (item._source[`aais_${line}_Jur_ss`]) {
      const states = item._source[`aais_${line}_Jur_ss`];
      if (Array.isArray(states)) {
        return `${lineName} states (${states.length}): ${states.join(", ")}`;
      }

      return `${lineName} states (1): ${item._source[`aais_${line}_Jur_ss`]}`;
    }
    return `${lineName} state (0)`;
  }

  function getProductLinesForItem(item, index) {
    if (item._source.productLines_ss) {
      if (Array.isArray(item._source.productLines_ss)) {
        return item._source.productLines_ss.map((line) => {
          return (
            <Tooltip
              placement="top"
              title={getLineToolTip(item, line)}
              key={`${item._id}${line}`}
            >
              <Chip
                variant={moreLine === line ? "default" : "outlined"}
                color="primary"
                size="small"
                label={line}
                className={classes.chip}
                onClick={() => {
                  setMoreLine(line);
                }}
              />
            </Tooltip>
          );
        });
      }

      return (
        <Tooltip
          title={getLineToolTip(item, item._source.productLines_ss)}
          placement="top"
        >
          <Chip
            key={`${item._source.productLines_ss}-line`}
            variant={
              moreLine === item._source.productLines_ss ? "default" : "outlined"
            }
            color="primary"
            size="small"
            label={item._source.productLines_ss}
            data-test={`browseResults-item-${index}-product-chip`}
          />
        </Tooltip>
      );
    }
  }

  function getStatesForLine(item) {
    if (item._source[`aais_${moreLine}_Jur_ss`]) {
      const states = item._source[`aais_${moreLine}_Jur_ss`];
      if (Array.isArray(states)) {
        return states.map((state) => (
          <Chip
            key={state}
            variant="outlined"
            label={state}
            size="small"
            className={classes.chip}
            data-test={`chip${state}`}
          />
        ));
      }
      // {states.length === 0 && <Typography>No state added</Typography>}
    }

    const state = item._source[`aais_${moreLine}_Jur_ss`];

    if (!state) {
      return <Typography>{`No states found for ${moreLine}`}</Typography>;
    }

    return (
      <Chip
        variant="outlined"
        size="small"
        label={state}
        className={classes.chip}
        data-test={`chip${state}`}
      />
    );
  }

  function toggleExpanded(item) {
    setMoreLine(props.line); //always reset it back to default

    if (expandedCard === item._id) {
      setExpandedCard(null);
      return;
    }
    setExpandedCard(item._id);
  }

  function getTypeIcon(item, index) {
    switch (item?._source?.packageType_s) {
      case "PFM": {
        return (
          <Tooltip
            title={PACKAGE_TYPES.FORMS.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-form-tooltip`,
            }}
            placement="top"
          >
            <FormIcon
              color="disabled"
              data-test={`browseResults-item-${index}-form-icon`}
            ></FormIcon>
          </Tooltip>
        );
      }
      case "PLC": {
        return (
          <Tooltip
            title={PACKAGE_TYPES.LOSS_COST.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-lossCost-tooltip`,
            }}
            placement="top"
          >
            <LossCostIcon
              color="disabled"
              data-test={`browseResults-item-${index}-lossCost-icon`}
            ></LossCostIcon>
          </Tooltip>
        );
      }
      case "PRI": {
        return (
          <Tooltip
            title={PACKAGE_TYPES.RATING_INFO.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-ratingInformation-tooltip`,
            }}
            placement="top"
          >
            <RatingIcon
              color="disabled"
              data-test={`browseResults-item-${index}-ratingInformation-icon`}
            ></RatingIcon>
          </Tooltip>
        );
      }
      case "PRL": {
        return (
          <Tooltip
            title={PACKAGE_TYPES.RULES.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-rules-tooltip`,
            }}
            placement="top"
          >
            <RuleIcon
              color="disabled"
              data-test={`browseResults-item-${index}-rules-icon`}
            ></RuleIcon>
          </Tooltip>
        );
      }
      case "SUP":
      case "IMS": {
        return (
          <Tooltip
            title={PACKAGE_TYPES.SUPPORTING_DOCS.title}
            PopperProps={{
              "data-test": `browseResults-item-${index}-supportingInformation-tooltip`,
            }}
            placement="top"
          >
            <SupportingDocIcon
              color="disabled"
              data-test={`browseResults-item-${index}-supportingInformation-icon`}
            ></SupportingDocIcon>
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  }

  function getStatusIcon(item, index) {
    switch (item?._source?.status_s) {
      case "Externally Approved": {
        return (
          <Tooltip
            title="Current"
            PopperProps={{
              "data-test": `browseResults-item-${index}-externallyApproved-tooltip`,
            }}
            placement="top"
          >
            <CurrentIcon
              className={classes.currentIcon}
              data-test={`browseResults-item-${index}-externallyApproved-icon`}
            ></CurrentIcon>
          </Tooltip>
        );
      }
      case "Superseded": {
        return (
          <Tooltip
            title="Not Current"
            PopperProps={{
              "data-test": `browseResults-item-${index}-superseded-tooltip`,
            }}
            placement="top"
          >
            <NotCurrent
              className={classes.notCurrentIcon}
              data-test={`browseResults-item-${index}-superseded-icon`}
            ></NotCurrent>
          </Tooltip>
        );
      }
      default: {
        return null;
      }
    }
  }

  function getProductLineCount(item) {
    if (item._source.productLines_ss) {
      if (Array.isArray(item._source.productLines_ss)) {
        return item._source.productLines_ss.length;
      }

      return 1;
    }

    return 0;
  }

  function getStateCount(item) {
    if (item._source[`aais_${props.line}_Jur_ss`]) {
      const states = item._source[`aais_${props.line}_Jur_ss`];
      if (Array.isArray(states)) {
        return states.length;
      }

      return 1;
    }

    return 0;
  }

  function getFormInfo(item, index) {
    let label = "";

    if (item._source.packageType_s === "PFM") {
      label = `${item._source.documentNumber_s} ${item._source.formEdition_s}`;
    } else if (
      item._source.packageType_s === "PLC" ||
      item._source.packageType_s === "PRI" ||
      item._source.packageType_s === "PRL"
    ) {
      label = `${item._source.documentNumber_s} ${item._source.manualRevision_s}`;
    }

    return label;
  }

  function getHighlight(item) {
    if (
      item.highlight &&
      item.highlight.text &&
      item.highlight.text.length > 0
    ) {
      const text = item.highlight.text.join("   ");
      // if (text.length > 230) {
      //   text = text.substring(0, 230);
      //   text += "...";
      // }
      return { __html: text };
    }
    return { __html: "" };
  }

  return (
    <React.Fragment>
      <Container maxWidth={false} disableGutters>
        <Grid container>
          <Grid item xs={12} className={classes.grow}>
            <AppBar position="static" color="inherit">
              <Toolbar>
                <Hidden mdUp>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={() => props.setDrawerOpen(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
                <Typography variant="h6">Browse</Typography>
                {props?.results?.hits ? (
                  <Typography className={classes.hits}>{`${
                    props.results.hits.total.value
                  } results (${convertMsToSeconds(
                    props.results.took
                  )} seconds)`}</Typography>
                ) : (
                  <div className={classes.grow}></div>
                )}
                <Button
                  color="primary"
                  variant="outlined"
                  data-test="browseScreen-item-download-button"
                  onClick={props.download}
                  disabled={(props?.results?.hits?.total?.value ?? -1) <= 0}
                >
                  Download
                </Button>
              </Toolbar>
            </AppBar>
          </Grid>
        </Grid>
      </Container>
      {props.loading && <LinearProgress></LinearProgress>}
      <InfiniteScroll
        dataLength={props?.results?.hits?.hits?.length ?? 0}
        hasMore={
          props?.results?.hits?.total?.value >
          props?.results?.hits?.hits?.length
        }
        next={props.loadMore}
        loader={
          <div className={classes.loadingContainer}>
            <CircularProgress
              className={classes.progressBar}
              size={80}
            ></CircularProgress>
          </div>
        }
        className={classes.infiniteScroll}
        // scrollableTarget="body"
      >
        <div className={classes.resultsContainer}>{getItems()}</div>
      </InfiniteScroll>
      {previewItem && (
        <AAISPDFViewerDialog
          s3Key={previewItem.rendition_locations_ss}
          title={previewItem.title}
          handleClose={() => setPreviewItem()}
        ></AAISPDFViewerDialog>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  hits: {
    fontSize: 14,
    color: "#979797",
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
  },
  item: {
    maxWidth: 240,
    minWidth: 200,
    marginTop: 7,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 0,
    flexGrow: 1,
  },
  "@media (max-width: 600px)": {
    item: {
      maxWidth: "100%",
      minWidth: "100%",
      marginTop: 7,
      marginLeft: 2,
      marginRight: 2,
      marginBottom: 0,
      flexGrow: 1,
    },
  },
  resultsContainer: {
    marginTop: 5,
    marginBottom: 50,
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(32),
  },
  title: {
    cursor: "pointer",
    paddingBottom: 2,
  },
  card: {
    // minHeight: 155,
    flexGrow: 1,
  },
  cardHeader: {
    // minHeight: 100,
    // border: "1px solid orange",
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  chipsPaper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  cardActions: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
  },
  highlight: {
    fontSize: 13,
    color: theme.palette.grey[600],
    height: 100,
    flexGrow: 1,
    overflowY: "hidden",
    //border: "1px solid green",
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  expandButton: {
    marginLeft: "auto !important",
  },
  divider: {
    marginTop: 5,
  },
  infiniteScroll: {
    overflow: "visible !important",
  },
}));
