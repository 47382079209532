import React, { useState } from "react";
import {
  Grid,
  Container,
  CardContent,
  Card,
  CardHeader,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LINES from "../enums/LINES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import STATES from "../enums/STATES";
import Menu from "@material-ui/core/Menu";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import NotCurrent from "@material-ui/icons/NotInterested";
import CurrentIcon from "@material-ui/icons/Check";
import FormIcon from "@material-ui/icons/ListAlt";
import RuleIcon from "@material-ui/icons/Gavel";
import RatingIcon from "@material-ui/icons/StarRate";
import LossCostIcon from "@material-ui/icons/AttachMoney";
import StatesIcon from "@material-ui/icons/Public";
import SupportingDocIcon from "@material-ui/icons/AttachFile";

export default function BrowsePicker(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleLineChange = (event) => {
    props.setLine(event.target.value);
    if (!LINES[event?.target?.value]?.classes) {
      props.setLineClass("");
    }

    props.setPackageType("");

    if (!event.target.value) {
      //if you reset the line clear package type + docs
      props.setSupportingDocsType("");
    }
  };

  const handleLineClassChange = (event) => {
    props.setLineClass(event.target.value);
  };

  const handlePackageTypeChange = (event) => {
    props.setPackageType(event.target.value);
    props.setSupportingDocsType("");
  };

  const handleSupportingDocsTypeChange = (event) => {
    props.setSupportingDocsType(event.target.value);
  };

  const handleStatusChange = (event) => {
    props.setStatus(event.target.value);
  };

  function getProductLineSection() {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={true}
        required
      >
        <InputLabel id="product-select-label">Product</InputLabel>
        <Select
          labelId="product-select-label"
          id="product-select"
          data-test="browseProduct"
          value={props.line}
          onChange={handleLineChange}
          label="Product"
        >
          <MenuItem key="emptyItem" value="" onClick={() => {}}>
            None
          </MenuItem>
          {Object.keys(LINES).map((aLine) => {
            if (LINES[aLine].notVisible) return null;

            return (
              <MenuItem
                key={aLine}
                value={aLine}
                onClick={() => {}}
                selected={props.line === aLine}
              >
                {`${aLine} - ${LINES[aLine].title}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function handleClickForMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function addState(state) {
    const tempStates = props.states.concat([state]);
    tempStates.sort();
    props.setStates(tempStates);
    setAnchorEl(null);
  }

  function deleteState(state) {
    const tempStates = props.states.filter((aState) => aState !== state);
    props.setStates(tempStates);
  }

  function getStateSection() {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Button
            aria-controls="states-menu"
            aria-haspopup="true"
            onClick={handleClickForMenu}
            color="primary"
            variant="outlined"
            data-test="addState"
            fullWidth={true}
            startIcon={<StatesIcon color="disabled" />}
          >
            Add State
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={0}>
            {props.states.map((stateKey) => (
              <Chip
                key={stateKey}
                variant="outlined"
                // color="primary"
                avatar={<Avatar>{STATES[stateKey].key}</Avatar>}
                onDelete={() => deleteState(stateKey)}
                className={classes.chip}
                data-test={`chip${STATES[stateKey].key}`}
              />
            ))}
            {props.states.length === 0 && (
              <Typography>No state added</Typography>
            )}
          </Paper>
        </Grid>
        <Menu
          id="states-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {Object.keys(STATES).map((stateKey) => (
            <MenuItem
              onClick={() => addState(stateKey)}
              key={stateKey}
              disabled={props.states.includes(stateKey)}
              data-test={`select${STATES[stateKey].key}`}
            >
              {STATES[stateKey].title}
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }

  function getPackageTypeSection() {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={true}
        required
        disabled={!props.line}
      >
        <InputLabel id="packageType-select-label">Material Type</InputLabel>
        <Select
          labelId="packageType-select-label"
          id="packageType-select"
          data-test="packageType"
          value={props.packageType}
          onChange={handlePackageTypeChange}
          label="Material Type"
        >
          <MenuItem key="emptyItem" value={""} onClick={() => {}}>
            None
          </MenuItem>
          <MenuItem
            value={PACKAGE_TYPES.FORMS.packageType_s}
            onClick={() => {}}
            selected={props.packageType === PACKAGE_TYPES.FORMS.packageType_s}
          >
            <FormIcon color="disabled"></FormIcon>
            <Typography display="inline" className={classes.menuItemText}>
              {`${PACKAGE_TYPES.FORMS.title}`}
            </Typography>
          </MenuItem>
          <MenuItem
            value={PACKAGE_TYPES.LOSS_COST.packageType_s}
            onClick={() => {}}
            selected={
              props.packageType === PACKAGE_TYPES.LOSS_COST.packageType_s
            }
          >
            <LossCostIcon color="disabled"></LossCostIcon>
            <Typography display="inline" className={classes.menuItemText}>
              {`${PACKAGE_TYPES.LOSS_COST.title}`}
            </Typography>
          </MenuItem>
          <MenuItem
            value={PACKAGE_TYPES.RULES.packageType_s}
            onClick={() => {}}
            selected={props.packageType === PACKAGE_TYPES.RULES.packageType_s}
          >
            <RuleIcon color="disabled"></RuleIcon>
            <Typography display="inline" className={classes.menuItemText}>
              {`${PACKAGE_TYPES.RULES.title}`}
            </Typography>
          </MenuItem>
          <MenuItem
            value={PACKAGE_TYPES.RATING_INFO.packageType_s}
            onClick={() => {}}
            selected={
              props.packageType === PACKAGE_TYPES.RATING_INFO.packageType_s
            }
          >
            <RatingIcon color="disabled"></RatingIcon>
            <Typography display="inline" className={classes.menuItemText}>
              {`${PACKAGE_TYPES.RATING_INFO.title}`}
            </Typography>
          </MenuItem>
          {props.line !== LINES.IMG.key && (
            <MenuItem
              value={PACKAGE_TYPES.SUPPORTING_DOCS.packageType_s}
              onClick={() => {}}
              selected={
                props.packageType ===
                PACKAGE_TYPES.SUPPORTING_DOCS.packageType_s
              }
            >
              <SupportingDocIcon color="disabled"></SupportingDocIcon>
              <Typography display="inline" className={classes.menuItemText}>
                {`${PACKAGE_TYPES.SUPPORTING_DOCS.title}`}
              </Typography>
            </MenuItem>
          )}
          {props.line === LINES.IMG.key && (
            <MenuItem
              value={PACKAGE_TYPES.SUPPORTING_DOCS_IMG.packageType_s}
              onClick={() => {}}
              selected={
                props.packageType ===
                PACKAGE_TYPES.SUPPORTING_DOCS_IMG.packageType_s
              }
            >
              <SupportingDocIcon color="disabled"></SupportingDocIcon>
              <Typography display="inline" className={classes.menuItemText}>
                {`${PACKAGE_TYPES.SUPPORTING_DOCS_IMG.title}`}
              </Typography>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    );
  }

  function getSupportingDocsSection() {
    let packageTypeKey;
    if (props?.packageType === PACKAGE_TYPES.SUPPORTING_DOCS.packageType_s) {
      packageTypeKey = PACKAGE_TYPES.SUPPORTING_DOCS.key;
    } else if (
      props?.packageType === PACKAGE_TYPES.SUPPORTING_DOCS_IMG.packageType_s
    ) {
      packageTypeKey = PACKAGE_TYPES.SUPPORTING_DOCS_IMG.key;
    } else return null;

    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={true}
      >
        <InputLabel id="supporting-docs-type-select-label">Doc Type</InputLabel>
        <Select
          labelId="supporting-docs-type-select-label"
          id="supporting-docs-type-select"
          data-test="browseFilter-item-supportingDocsType"
          value={props.supportingDocsType}
          onChange={handleSupportingDocsTypeChange}
          label="Classes"
        >
          <MenuItem key="emptyItem" value={""} onClick={() => {}}>
            None
          </MenuItem>
          {Object.keys(PACKAGE_TYPES[packageTypeKey].types).map((type) => {
            return (
              <MenuItem
                key={type}
                value={type}
                onClick={() => {}}
                selected={
                  props.supportingDocsType ===
                  PACKAGE_TYPES[packageTypeKey].types[type].documentType_s_query
                }
              >
                {`${PACKAGE_TYPES[packageTypeKey].types[type].documentType_s_query} - ${PACKAGE_TYPES[packageTypeKey].types[type].title}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function getClassesSection(line) {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={true}
      >
        <InputLabel id="classes-select-label">Classes</InputLabel>
        <Select
          labelId="classes-select-label"
          id="classes-select"
          data-test="classes"
          value={props.lineClass}
          onChange={handleLineClassChange}
          label="Classes"
        >
          <MenuItem key="emptyItem" value={""} onClick={() => {}}>
            None
          </MenuItem>
          {Object.keys(LINES[line].classes).map((type) => {
            return (
              <MenuItem
                key={type}
                value={type}
                onClick={() => {}}
                selected={props.lineClass === type}
              >
                {`${type} - ${LINES[line].classes[type].title}`}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }

  function handlekeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      props.setTerm(searchTerm);
    }
  }

  function getStatusSection() {
    return (
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={true}
      >
        <InputLabel id="packageType-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          data-test="status"
          value={props.status}
          onChange={handleStatusChange}
          label="Status"
        >
          <MenuItem
            value={"Externally Approved"}
            onClick={() => {}}
            selected={props.status === "Externally Approved"}
          >
            <CurrentIcon className={classes.currentIcon}></CurrentIcon>
            <Typography display="inline" className={classes.menuItemText}>
              Current
            </Typography>
          </MenuItem>
          <MenuItem
            value={"Superseded"}
            onClick={() => {}}
            selected={props.status === "Superseded"}
          >
            <NotCurrent className={classes.notCurrentIcon}></NotCurrent>
            <Typography display="inline" className={classes.menuItemText}>
              Not Current
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  function getSearchSection() {
    return (
      <TextField
        variant="outlined"
        fullWidth
        autoCorrect="off"
        spellCheck="false"
        label="Search"
        placeholder="Search"
        data-test="browseScreenSearch"
        value={searchTerm}
        onChange={(event) => setSearchTerm(event.target.value)}
        inputProps={{
          onKeyDown: handlekeyDown,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              {searchTerm && (
                <IconButton
                  type="submit"
                  color="primary"
                  onClick={() => {
                    props.setTerm("");
                    setSearchTerm("");
                  }}
                  component="span"
                >
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      ></TextField>
    );
  }

  return (
    <Container disableGutters className={classes.container}>
      <Card elevation={5}>
        <CardHeader
          className={classes.cardHeader}
          title="Filter"
          action={
            <IconButton
              aria-label="clear"
              color="primary"
              onClick={() => {
                props.clearAll();
                setSearchTerm("");
              }}
            >
              <ClearIcon />
            </IconButton>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.section}>
              {getProductLineSection()}
            </Grid>
            {LINES[props.line]?.classes && (
              <Grid item xs={12} className={classes.section}>
                {getClassesSection(props.line)}
              </Grid>
            )}
            <Grid
              item
              container
              xs={12}
              spacing={1}
              className={classes.section}
            >
              {getStateSection()}
            </Grid>

            <Grid item xs={12} className={classes.section}>
              {getPackageTypeSection()}
            </Grid>

            {(props?.packageType ===
              PACKAGE_TYPES.SUPPORTING_DOCS.packageType_s ||
              props?.packageType ===
                PACKAGE_TYPES.SUPPORTING_DOCS_IMG.packageType_s) && (
              <Grid item xs={12} className={classes.section}>
                {getSupportingDocsSection()}
              </Grid>
            )}

            <Grid item xs={12} className={classes.section}>
              {getStatusSection()}
            </Grid>

            <Grid item xs={12}>
              {getSearchSection()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    // maxWidth: 300,
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  section: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  currentIcon: {
    color: "#4caf50",
  },
  notCurrentIcon: {
    color: "#dc004e",
  },
  menuItemText: {
    paddingLeft: 5,
    verticalAlign: "bottom",
  },
}));
