import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import * as userUtil from "../util/userUtil";
import * as UserApi from "../apis/UserApi";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ROLES, { getRoleByKey } from "../enums/ROLES";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import PickAUserDialog from "./PickAUserDialog";
import MeContext from "../contexts/MeContext";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
  disabledRow: {
    backgroundColor: theme.palette.secondary.light,
  },
});

function AllUsersTable(props) {
  const { classes } = props;

  const [loadingUserId, setLoadingUserId] = useState();
  const [loadingGeneric, setLoadingGeneric] = useState();
  const [users, setUsers] = useState(UserApi.getAllFromCache());
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [snack, setSnack] = React.useState({ open: false, note: null });
  const [nonce, setNonce] = useState(false);

  const me = useContext(MeContext);

  useEffect(() => {
    UserApi.getAll().then((users) => {
      setUsers(users);
    });
  }, [nonce]);

  function getTableRow(user) {
    if (!user) return null;

    let picture;
    if (user.picture) {
      picture = user.picture;
    }

    const meString = me.userId === user.userId ? " (me)" : "";
    const disabledString = user.disabled === undefined ? "" : " (disabled)";

    return (
      <React.Fragment key={`${user.userId}-section`}>
        {loadingUserId === user.userId && (
          <TableRow key={`${user.userId}-load`} className={classes.loadingRow}>
            <TableCell colSpan={5} className={classes.loadingCell}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
        <TableRow
          key={user.userId}
          hover={true}
          className={
            user.disabled === undefined ? undefined : classes.disabledRow
          }
        >
          <TableCell component="th" scope="row">
            <Avatar alt={userUtil.getDisplayName(user)} src={picture}>
              {!picture ? userUtil.getInitials(user) : null}
            </Avatar>
          </TableCell>
          <TableCell component={Link} to={userUtil.getProfileLink(user.userId)}>
            <Typography variant="body1">
              {userUtil.getDisplayName(user)}
              {meString}
              {disabledString}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography>{user.orgs ? user.orgs.length : 0}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{getRoleForm(user)}</Typography>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color={user.disabled === undefined ? "primary" : "secondary"}
              onClick={() =>
                handleDisableChange(user, user.disabled === undefined)
              }
            >
              {user.disabled !== undefined ? "Enable" : "Disable"}
            </Button>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  async function handleRoleChange(user, role) {
    setLoadingUserId(user.userId);
    await UserApi.updateAppRole(user.userId, role);
    setNonce(!nonce); //flip the bit to reload
    setLoadingUserId();
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} role updated to ${role.title}`,
    });
  }

  async function handleDisableChange(user, disableUser) {
    setLoadingUserId(user.userId);
    if (disableUser) {
      await UserApi.disableUser(user.userId);
    } else {
      await UserApi.enableUser(user.userId);
    }
    setUsers(UserApi.getAllFromCache());
    setLoadingUserId();
  }

  function handleAddUser(user) {
    setLoadingGeneric(true);
    setShowAddUserDialog(false);
    setUsers(UserApi.getAllFromCache());
    setLoadingGeneric(false);
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} has been created`,
    });
  }

  function getRoleForm(user) {
    if (!userUtil.isEmployee(user)) return;

    const meRO = userUtil.canDoApp(me, ROLES.READ_ONLY);
    const meRW = userUtil.canDoApp(me, ROLES.READ_WRITE);
    const meADMIN = userUtil.canDoApp(me, ROLES.ADMIN);

    let role = ROLES.READ_ONLY;
    if (user.apps) {
      const app = user.apps.find((app) => app.appName === "mss");
      if (app) {
        role = getRoleByKey(app.roleKey);
      }
    }

    return (
      <FormControl
        variant="outlined"
        //disabled if not enabled or only have RO role
        disabled={meRO && !meRW && !meADMIN}
      >
        <Select
          value={role}
          onChange={(event) => handleRoleChange(user, event.target.value)}
          input={
            <OutlinedInput labelWidth={0} className={classes.roleKeySelector} />
          }
        >
          {meADMIN && (
            //if its enabled and me is a admin show the option
            <MenuItem value={ROLES.ADMIN}>{ROLES.ADMIN.title}</MenuItem>
          )}
          {meRW && (
            //if its enabled and me is RW show the option
            <MenuItem value={ROLES.READ_WRITE}>
              {ROLES.READ_WRITE.title}
            </MenuItem>
          )}

          <MenuItem value={ROLES.READ_ONLY}>{ROLES.READ_ONLY.title}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h5"
                component="h4"
                data-testid="usersCountLabel" // FIXME: Deprecate data-testid
                data-test="usersCountLabel"
                className={classes.grow}
              >
                Users ({users.length})
              </Typography>
            </Grid>
            <Grid item>
              {/* <Fab
                data-testid="addUserButton" // FIXME: Deprecate data-testid
                data-test="addUserButton"
                size="small"
                color="primary"
                aria-label="Add"
                onClick={() => setShowAddUserDialog(true)}
              >
                <AddIcon />
              </Fab> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loadingGeneric && <LinearProgress data-testid="progressBar" />}
      {users && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Avatar</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Orgs</Typography>
                </TableCell>
                <TableCell>
                  <Typography>MSS Role</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Disable</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return getTableRow(user);
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {showAddUserDialog && (
        <PickAUserDialog
          handleClose={() => setShowAddUserDialog(false)}
          handleAddUser={handleAddUser}
          addText="Add"
        />
      )}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(AllUsersTable);
