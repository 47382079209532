import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import * as AssetApi from "../apis/AssetApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "@material-ui/core";
import LINES from "../enums/LINES";

export default function MyRecentProductsCard(props) {
  const classes = useStyles();
  const [manifests, setManifests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistory();
  }, [props.lineKey]);

  async function getHistory() {
    const data = await AssetApi.getUserManifestMetaData(props.userId);
    setManifests(data);
    setLoading(false);
  }

  function getList() {
    const lineKeys = [];
    manifests.forEach(manifest => {
      if (!lineKeys.includes(manifest.request.product))
        lineKeys.push(manifest.request.product);
    });

    lineKeys.splice(5); // only show the last 5

    return lineKeys.map(lineKey => (
      <Typography key={lineKey}>
        <Link href={`#/lines/${lineKey}`}>
          {`${LINES[lineKey].title} (${lineKey})`}
        </Link>
      </Typography>
    ));
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`My Recent Products`}
          />
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              {!loading && manifests && manifests.length === 0 && (
                <Typography>No favorites yet. Keep browsing</Typography>
              )}
              {!loading && manifests && manifests.length !== 0 && getList()}
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
