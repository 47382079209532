import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import STATES from "../enums/STATES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import Paper from "@material-ui/core/Paper";
import * as AssetApi from "../apis/AssetApi";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function DownloadCSVMaterialsCard(props) {
  const classes = useStyles();
  const [states] = useState(
    Object.keys(STATES).map((state) => STATES[state].key)
  );
  const [packageTypes, setPackageTypes] = useState([
    PACKAGE_TYPES.FORMS.key,
    PACKAGE_TYPES.LOSS_COST.key,
    PACKAGE_TYPES.RULES.key,
    PACKAGE_TYPES.RATING_INFO.key,
  ]);

  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  function togglePackageTypes(packageTypeKey) {
    if (packageTypes.includes(packageTypeKey)) {
      const tempPackageTypes = packageTypes.filter(
        (key) => packageTypeKey !== key
      );
      setPackageTypes(tempPackageTypes);
    } else {
      setPackageTypes(packageTypes.concat(packageTypeKey));
    }
  }

  function getSubheader() {
    let title = packageTypes.map(
      (packageType) => PACKAGE_TYPES[packageType].title
    );
    title = title.sort();
    title = title.join(", ");
    //eslint-disable-next-line no-useless-concat
    title = title.replace(/,([^,]*)$/, " and " + "$1");
    return title;
  }

  async function downloadMaterials() {
    setLoading(true);
    const manifest = await createManifest();
    if (!manifest) {
      alert("There are no files to be delivered");
      setLoading(false);
      return;
    }

    const files = manifest.files;
    const constString = convertToCSV(files);
    exportCSVFile(constString);
    setLoading(false);
  }

  async function createManifest() {
    if (
      !props.lineKey ||
      !Array.isArray(packageTypes) ||
      packageTypes.length <= 0
    ) {
      return null;
    }

    const manifest = await AssetApi.createManifestV1(
      props.lineKey,
      states,
      packageTypes,
      false //dont persist
    );
    if (manifest.files && manifest.files.length === 0) {
      return;
    }
    return manifest;
  }

  function convertToCSV(files) {
    let str = "Product Line,File Name,File Path,File Key\r\n";
    console.log("array", files, "length", files.length);
    for (let i = 0; i < files.length; i++) {
      const line = `${props.lineKey},${files[i].name},${files[i].path},${files[i].key}\r\n`;
      str += line;
    }

    return str;
  }

  function exportCSVFile(items) {
    const exportedFilename =
      `${
        props.lineKey
      }-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.csv`.replace(
        / /,
        "-"
      ) || "export.csv";

    const blob = new Blob([items], { type: "text/csv;charset=utf-8;" });
    // if (navigator.msSaveBlob) { // IE 10+
    //     navigator.msSaveBlob(blob, exportedFilenmae);
    // } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // }
    }
  }
  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`Download Latest ${props.lineKey} Files List (.csv)`}
            subheader={getSubheader()}
          />
          {loading && <LinearProgress />}
          <CardContent>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="body1">
                Download a list of all files in the latest Program Materials
              </Typography>
            </Paper>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="body1" className={classes.internalUse}>
                AAIS Internal Use Only
              </Typography>
            </Paper>
          </CardContent>
          <CardActions>
            <Grid container spacing={1} justify="space-between">
              <Grid item container spacing={1} xs={9}>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => downloadMaterials()}
                    disabled={states.length === 0 || packageTypes.length === 0}
                  >
                    Download
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={3} container justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    size="small"
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? "less" : "more"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardActions>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Grid container>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(PACKAGE_TYPES.FORMS.key)}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.FORMS.key)
                        }
                        value={packageTypes.includes(PACKAGE_TYPES.FORMS.key)}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.FORMS.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(
                          PACKAGE_TYPES.LOSS_COST.key
                        )}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.LOSS_COST.key)
                        }
                        value={packageTypes.includes(
                          PACKAGE_TYPES.LOSS_COST.key
                        )}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.LOSS_COST.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(PACKAGE_TYPES.RULES.key)}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.RULES.key)
                        }
                        value={packageTypes.includes(PACKAGE_TYPES.RULES.key)}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.RULES.title}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={packageTypes.includes(
                          PACKAGE_TYPES.RATING_INFO.key
                        )}
                        onChange={() =>
                          togglePackageTypes(PACKAGE_TYPES.RATING_INFO.key)
                        }
                        value={packageTypes.includes(
                          PACKAGE_TYPES.RATING_INFO.key
                        )}
                        color="primary"
                      />
                    }
                    label={PACKAGE_TYPES.RATING_INFO.title}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#ececfa",
  },
  paper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    padding: theme.spacing(0.5),
    width: "100%",
  },
  internalUse: {
    color: theme.palette.grey[600],
    paddingTop: theme.spacing(1),
  },
}));
