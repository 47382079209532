import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as UserApi from "../apis/UserApi";

function PickAUserDialog(props) {
  const { classes, userIds } = props;

  const [users, setUsers] = useState(UserApi.getUsersFromCache(userIds));
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
      data-testid="pickAUserDialog" // FIXME: Deperecates data-testid
      data-test="pickAUserDialog"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title">Find User</DialogTitle>
      {users && (
        <DialogContent>
          <Autocomplete
            options={users}
            fullWidth={true}
            data-test="pickUser"
            getOptionLabel={(user) => user.email}
            renderInput={(params) => (
              <TextField {...params} label="Select a user" variant="outlined" />
            )}
            onChange={(event, value) => setSelectedUser(value)}
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => props.handleSelectUser(selectedUser)}
          color="primary"
          variant="contained"
          disabled={!selectedUser}
        >
          Select user
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    textDecoration: "none",
    lineHeight: 1,
  },
  email: {
    color: "gray", //theme.palette.grey[100],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(PickAUserDialog);
