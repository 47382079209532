import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import * as UserApi from "../apis/UserApi";

export default function ProductAdminLinksCard(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  async function downloadMaterials() {
    setLoading(true);
    const users = await UserApi.getUsersWithRecentNotifications();
    if (!users || users.length <= 0) {
      alert("There are no users with recent notifications");
      setLoading(false);
      return;
    }

    const constString = convertToCSV(users);
    exportCSVFile(constString);
    setLoading(false);
  }

  function convertToCSV(users) {
    let str = "userId,email,firstName,lastName\r\n";
    console.log("array", users, "length", users.length);
    for (let i = 0; i < users.length; i++) {
      const line = `${users[i].userId},${users[i].email},${users[i].firstName},${users[i].lastName}\r\n`;
      str += line;
    }

    return str;
  }

  function exportCSVFile(items) {
    const exportedFilename =
      `UsersWithRecentUnreadNotifications-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}.csv`.replace(
        / /,
        "-"
      ) || "export.csv";

    const blob = new Blob([items], { type: "text/csv;charset=utf-8;" });
    // if (navigator.msSaveBlob) { // IE 10+
    //     navigator.msSaveBlob(blob, exportedFilenmae);
    // } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // }
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={`HubSpot Utils`} />
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              <Button color="primary" onClick={downloadMaterials}>
                Get users with unread notifications
              </Button>
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: "#ececfa",
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
