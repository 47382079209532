/**
 * @description This is the User Card component that is used
 * throught the application. It is used to quickly show a user
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module components/UserCard
 */

import React, { useState, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PHONE_TYPE from "../enums/PHONE_TYPE";
import * as userUtil from "../util/userUtil";
import * as UserApi from "../apis/UserApi";
import MaterialUILink from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import ReloadContext from "../contexts/ReloadContext";
import LinearProgress from "@material-ui/core/LinearProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";

/**
 * @description The functional component for the User Card
 * @since 1.0.0
 * @public
 */
function UserInfo(props) {
  const { classes, user } = props;
  const reloadScreen = useContext(ReloadContext);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [snack, setSnack] = React.useState({ open: false, note: "hello michelle" });

  const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);
  const initPhoneNumber = phone && phone.phoneNumber ? phone.phoneNumber : null;
  const initExtension = phone && phone.extension ? phone.extension : null;
  const countryCode = "+1";

  const [phoneNumber, setPhoneNumber] = useState(initPhoneNumber);
  const [phoneExtension, setPhoneExtension] = useState(initExtension);

  useEffect(() => {
    console.log("snackbar changed", snack);
  }, [snack]);

  async function handleUpdate() {
    console.log("handle called", firstName, lastName, phoneNumber, phoneExtension);
    setLoading(true);

    //update name
    if (!firstName) {
      console.error("firstName not found showing error");
      updateFailed("Firstname");
    }

    if (!lastName) {
      updateFailed("Lastname");
    }

    if (user.firstName !== firstName || user.lastName !== lastName) {
      await UserApi.updateName(user.userId, firstName, lastName);
    }

    const oldPhone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);

    if (!phoneNumber && phoneExtension) {
      updateFailed("Phone");
    }

    if (
      !oldPhone ||
      phoneNumber !== oldPhone.phoneNumber ||
      phoneExtension !== oldPhone.extension
    ) {
      if (!oldPhone || !oldPhone.phoneNumberId) {
        await UserApi.addPhoneNumber(
          user.userId,
          PHONE_TYPE.OFFICE,
          phoneNumber,
          countryCode,
          phoneExtension
        );
      } else {
        await UserApi.updatePhoneNumber(
          user.userId,
          PHONE_TYPE.OFFICE,
          oldPhone.phoneNumberId,
          phoneNumber,
          countryCode,
          phoneExtension
        );
      }
    }

    reloadScreen();
    setLoading(false);
  }

  function handleCancel() {
    setFirstName(user.firstName);
    setLastName(user.lastName);

    const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);
    const initPhoneNumber =
      phone && phone.phoneNumber ? phone.phoneNumber : null;
    const initExtension = phone && phone.extension ? phone.extension : null;
    setPhoneNumber(initPhoneNumber);
    setPhoneExtension(initExtension);
  }

  function updateFailed(sectionTitle) {
    console.log("snack bar open", sectionTitle);
    setSnack({
      open: true,
      note: `${sectionTitle} cannot be empty`,
    });
    console.log("snack done bar opening", sectionTitle);
  }

  async function handleDelete() {
    setLoading(true);
    const phone = userUtil.getPhoneNumber(user, PHONE_TYPE.OFFICE);

    await UserApi.deletePhoneNumber(
      user.userId,
      PHONE_TYPE.OFFICE,
      phone.phoneNumberId
    );

    setPhoneNumber("");
    setPhoneExtension("");

    reloadScreen();
    setLoading(false);
  }

  function handleUploadPicture() {
    const input = document.createElement("input");
    input.type = "file";
    const dataTestId = document.createAttribute("data-testid");
    dataTestId.value = "uploadFileInput";
    input.setAttributeNode(dataTestId);

    input.onchange = (e) => {
      const file = e.target.files[0];

      if (!file) return;

      setLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async (readerEvent) => {
        try {
          const content = readerEvent.target.result;
          const imageInBase64 = content.split(",")[1];
          const contentType = file.type;
          const fileExtension = contentType.split("/")[1];

          await UserApi.changeProfilePicture(
            user.userId,
            contentType,
            fileExtension,
            imageInBase64
          );
          reloadScreen();
        } catch (error) {
          alert("Can't procees picture info. Please try a different picture");
          console.error(error);
        } finally {
          setLoading(false);
        }
      };
    };

    input.click();
  }

  function getNameSection() {
    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.sectionTitle}>
          Name
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="First"
              value={firstName}
              data-test="firstName"
              onChange={(event) => setFirstName(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              label="Last"
              value={lastName}
              data-test="lastName"
              onChange={(event) => setLastName(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function getEmailSection() {
    return (
      <React.Fragment>
        <Typography variant="h5" className={classes.sectionTitle}>
          Email
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" className={classes.email}>
              <MaterialUILink href={`mailto:${user.email}`}>
                {user.email}
              </MaterialUILink>
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function getPhoneSection() {
    return (
      <React.Fragment>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h5" className={classes.sectionTitle}>
              Phone
            </Typography>
          </Grid>
          <Grid item>
            <IconButton color="primary" onClick={handleDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              label="Country Code"
              value={countryCode}
              margin="normal"
              variant="outlined"
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <TextField
              label="Phone"
              aria-label="Phone"
              placeholder="7705551234"
              inputProps={{ "data-test": "Phone" }}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Extension"
              aria-label="Extension"
              inputProps={{ "data-test": "Extension" }}
              placeholder="1234"
              value={phoneExtension}
              onChange={(event) => setPhoneExtension(event.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  if (!user) return <React.Fragment />;

  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {
        <Snackbar
          data-test="snackbar"
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={(e, reason) => {
              //TODO: this seems to be bug with material UI. the snackbar closes almost
              //immediately with clickaway event. not sure why
             if(reason !== "clickaway")  
              setSnack({ open: false, note: reason });
            }}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <Paper className={classes.paper}>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleUpdate}>
              Update
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            lg={2}
            xl={1}
            className={classes.left}
            direction="column"
          >
            <Grid item>
              <Avatar
                className={classes.avatar}
                alt={userUtil.getDisplayName(user)}
                src={user.picture}
                onClick={handleUploadPicture}
              >
                {!user.picture ? userUtil.getInitials(user) : null}
              </Avatar>
            </Grid>
            <Grid item>
              <Button
                className={classes.uploadButton}
                variant="outlined"
                color="primary"
                onClick={handleUploadPicture}
              >
                Upload Picture
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={10} xl={11} className={classes.right}>
            {getNameSection()}
            <Divider className={classes.divider} />
            {getEmailSection()}
            <Divider className={classes.divider} />
            {getPhoneSection()}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

const styles = (theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(20),
    textDecoration: "none",
  },
  email: {
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    width: "100%",
    height: "100%",
    maxHeight: 200,
    maxWidth: 200,
    minWidth: 50,
    minHeight: 50,
    cursor: "pointer",
  },
  uploadButton: {
    marginTop: 10,
  },
  paper: {
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 50,
  },
  section: {
    //border: "1px solid black",
    width: "100%",
  },
  left: {
    //border: "1px solid red",
  },
  right: {
    //border: "1px solid blue",
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export default withStyles(styles, { withTheme: true })(UserInfo);
