import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as moment from "moment";
import { Divider, LinearProgress } from "@material-ui/core";
import StatesDataQuality from "../components/StatesDataQuality";
import Collapse from "@material-ui/core/Fade";
import * as MdsApi from "../apis/MdsApi";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function BucketFileList(props) {
  const classes = useStyles();

  const [openBucketFileId, setOpenBucketFileId] = useState();

  if (!props?.bucketFiles || props?.bucketFiles?.length <= 0) return null;

  function getFileStatus(bucketFile) {
    switch (bucketFile.fileStatus) {
      case 1: {
        if (bucketFile?.progress !== undefined && bucketFile?.progress >= 0) {
          return (
            <ListItemText
              primary={`Uploading... ${
                bucketFile?.progress?.toFixed() ?? 0
              }% (Do not close browser)`}
              secondary={
                <LinearProgress
                  variant="determinate"
                  value={bucketFile?.progress ?? 0}
                />
              }
            />
          );
        }

        return (
          <ListItemText
            primary={`Upload failed`}
            secondary={"Upload the file and try again"}
          />
        );
      }
      case 2:
        return (
          <ListItemText
            primary={"File Received. It's now safe to close the browser"}
            secondary={
              <LinearProgress variant="buffer" value={10} valueBuffer={10} />
            }
          />
        );
      case 22:
        return (
          <ListItemText
            primary={`Processing File with ${
              bucketFile.recordCount
                ? bucketFile.recordCount.toLocaleString()
                : "N/A"
            } records`}
            secondary={
              <LinearProgress variant="buffer" value={20} valueBuffer={70} />
            }
          />
        );
      case 23:
        return (
          <ListItemText
            primary={"Process Completed"}
            secondary={`Processed ${
              bucketFile.recordCount
                ? bucketFile.recordCount.toLocaleString()
                : "N/A"
            } records`}
          />
        );
      default:
        return (
          <ListItemText
            primary={"Unknown Status"}
            secondary={`Please contact AAIS`}
          />
        );
    }
  }

  async function downloadReport(bucketId, bucketFileId) {
    const response = await MdsApi.getBucketFileExportSignedUrl(
      bucketId,
      bucketFileId
    );
    console.log(response);
    window.open(response.url, "_parent");
  }

  return (
    <List className={classes.root}>
      {props.bucketFiles.map((bucketFile) => {
        return (
          <React.Fragment key={bucketFile.bucketFileId}>
            <ListItem
              className={classes.listItem}
              divider
              onClick={() =>
                setOpenBucketFileId(
                  openBucketFileId === bucketFile.bucketFileId
                    ? null
                    : bucketFile.bucketFileId
                )
              }
            >
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <ListItemText
                    primary={`${bucketFile.filename}`}
                    // primaryTypographyProps={{ className: unreadTextClassName }}
                    secondary={`${bucketFile.bucketFileId}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {getFileStatus(bucketFile)}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <ListItemText
                    // primaryTypographyProps={{ className: unreadTextClassName }}
                    secondary={`${moment(bucketFile.createdDate).format(
                      "LLL"
                    )}`}
                    primary={moment(bucketFile.createdDate).fromNow()}
                  ></ListItemText>
                </Grid>
              </Grid>
              <ListItemSecondaryAction>
                {(bucketFile.exportFileStatus === 22 ||
                  bucketFile.exportFileStatus === 23) && (
                  <Button
                    edge="end"
                    color="primary"
                    variant="outlined"
                    startIcon={
                      bucketFile.exportFileStatus === 22 ? (
                        <CircularProgress size={12} />
                      ) : null
                    }
                    disabled={bucketFile.exportFileStatus === 22}
                    onClick={() =>
                      downloadReport(
                        bucketFile.bucketId,
                        bucketFile.bucketFileId
                      )
                    }
                  >
                    {bucketFile.exportFileStatus === 22
                      ? "Processing CSV"
                      : "Download CSV"}
                  </Button>
                )}
              </ListItemSecondaryAction>
            </ListItem>
            {openBucketFileId === bucketFile.bucketFileId && (
              <Collapse in={true} timeout={15000}>
                <StatesDataQuality
                  bucketId={bucketFile.bucketId}
                  bucketFileId={bucketFile.bucketFileId}
                ></StatesDataQuality>
              </Collapse>
            )}
            {/* <Divider></Divider> */}
          </React.Fragment>
        );
      })}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  unreadListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  unreadText: {
    fontWeight: "bold",
  },
  listItem: {
    cursor: "pointer",
  },
}));
