import React, { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import * as userUtil from "../util/userUtil";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import AdminLinksCard from "../components/ProductAdminLinksCard";
import HubSpotCSVCard from "../components/HubSpotCSVCard";
import { useLocation, Redirect } from "react-router-dom";

export default function EmployeeDashboardScreen(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Employee Dashboard",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, [location]);

  function getTitle() {
    return `AAIS Employee Dashboard`;
  }

  function getWelcome() {
    return (
      <React.Fragment>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.welcome}
          justify="space-between"
        >
          <Grid item>
            <Typography variant="h2">Welcome to AAISdirect</Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  if (me && me.email && !userUtil.isEmployee(me)) {
    return <Redirect to={{ pathname: "/403" }} />;
  }

  return (
    <React.Fragment>
      <SummaryBar title={getTitle()}>{getWelcome()}</SummaryBar>
      {me && userUtil.isEmployee(me) && (
        <Container>
          <Grid container className={classes.dashboard} spacing={2}>
            {me && me.userId && (
              <React.Fragment>
                <Grid item xs={12} sm={4}>
                  <AdminLinksCard></AdminLinksCard>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <HubSpotCSVCard></HubSpotCSVCard>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Container>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  welcome: {
    paddingTop: 20,
    height: "calc(100vh - 141px)",
    textAlign: "center",
  },
  dashboard: {
    paddingTop: theme.spacing(2),
  },
}));
