import React, { useContext, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import OrgsTable from "../components/OrgsTable";
import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import OrgDialog from "../components/OrgDialog";
import * as OrgApi from "../apis/OrgApi";
import * as userUtil from "../util/userUtil";
import LinearProgress from "@material-ui/core/LinearProgress";

function OrgsScreen(props) {
  const { classes } = props;
  const me = useContext(MeContext);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Orgs Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, []);

  useEffect(() => {
    getOrgs();
  }, []);

  async function getOrgs() {
    setLoading(true);
    const tempOrgs = await OrgApi.getAll();
    setOrgs(tempOrgs);
    setLoading(false);
  }

  function getTitle() {
    if (me && me.firstName) return `${me.firstName}'s Organizations`;

    return `Your Organizations`;
  }

  async function handleCreate(name) {
    setLoading(true);
    setCreateDialogOpen(false);
    const org = await OrgApi.create(name);
    await getOrgs();
    setLoading(false);
  }

  function getOrgsBar() {
    return (
      <Container maxWidth={false} className={classes.container}>
        <Paper className={classes.paper}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item></Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setCreateDialogOpen(true)}
              >
                Create Org
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }

  return (
    <React.Fragment>
      <SummaryBar expanded={false} title={getTitle()} />
      {me && userUtil.isEmployee(me) && getOrgsBar()}
      <Grid container spacing={0} className={classes.grid}>
        {loading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
        {me && me.userId && <OrgsTable orgs={orgs} me={me} />}
      </Grid>
      {createDialogOpen && (
        <OrgDialog
          handleCreate={handleCreate}
          handleClose={() => setCreateDialogOpen(false)}
        ></OrgDialog>
      )}
    </React.Fragment>
  );
}

const styles = (theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    paddingRight: 10,
  },
  right: {
    paddingLeft: 10,
  },
  paper: {
    padding: theme.spacing(1),
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    //paddingBottom: theme.spacing(4),
  },
});

export default withStyles(styles, { withTheme: true })(OrgsScreen);
