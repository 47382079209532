/**
 * @description The Footer
 * @module components/Footer
 */

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

function Footer(props) {
  const { classes } = props;

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={2} className={classes.left}>
            <Link
              variant="body2"
              href="mailto:softwaredevelopment@aaisonline.com"
              className={classes.link}
            >
              Contact
            </Link>
          </Grid>

          <Grid item spacing={4} container xs={10} justify="flex-end">
            <Grid item>
              <Link
                underline="none"
                variant="body2"
                className={classes.copyright}
              >
                &#169; {new Date().getFullYear()} - {` v`}
                {process.env.REACT_APP_VERSION}
              </Link>
            </Grid>
            <Grid item>
              <Link
                variant="body2"
                href="https://www.aaisonline.com/en/terms-of-use"
                className={classes.link}
              >
                Legal
              </Link>
            </Grid>
            <Grid item>
              <Link
                variant="body2"
                href="https://www.aaisonline.com"
                className={classes.link}
              >
                AAISonline
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    bottom: 0,
    position: "fixed",
    width: "100%",
    borderRadius: 0,
  },
  left: {
    marginLeft: 20,
  },
  link: {
    color: "white",
  },
  copyright: {
    color: theme.palette.grey["A100"],
  },
});

export default withStyles(styles, { withTheme: true })(Footer);
