import LINES from "./LINES";

/**
 * @description The enumeration of the Line Categories
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const LINE_CATEGORIES = {
  COMMERCIAL_LINES: {
    key: "CL",
    title: "Commercial Lines",
    description:
      "AAIS commercial lines programs are the foundation for competitive products in the standard commercial P&C market, or niche specialty markets.",
    lines: [
      LINES.AP,
      LINES.BOP,
      LINES.CAB,
      LINES.CPP,
      LINES.CAU,
      LINES.CR,
      LINES.CCY,
      LINES.CIM,
      LINES.CLT,
      LINES.CL,
      LINES.COP,
      LINES.CXL,
      LINES.CP,
      LINES.CUP,
      LINES.DOP,
      LINES.GS,
      LINES.IMG,
    ],
    color: "#522e6d",
  },
  INLAND_MARINE: {
    key: "IM",
    title: "Inland Marine",
    description:
      "AAIS is the industry leader in the development and maintenance of commercial inland marine and output coverage programs.",
    lines: [
      LINES.BT,
      LINES.CIM,
      LINES.COP,
      LINES.CXL,
      LINES.DOP,
      LINES.FIM,
      LINES.IMG,
      LINES.PIM,
      LINES.YT,
    ],
    color: "#5091cd",
  },
  FARM_AND_AG: {
    key: "FA",
    title: "Farm and AG",
    description:
      "AAIS is a market leader in the farm and agriculture insurance market, providing innovative advisory solutions for property and liability exposures.",
    lines: [
      LINES.AGXL,
      LINES.AGGL,
      LINES.AGOP,
      LINES.FO,
      LINES.FCY,
      LINES.FIM,
      LINES.FP,
      LINES.FUP,
    ],
    color: "#b1bd22",
  },
  PERSONAL_LINES: {
    key: "PL",
    title: "Personal Lines",
    description:
      "AAIS has been pro-active in giving members personal lines product innovations that support differentiation and a competitive advantage.",
    lines: [
      LINES.AU,
      LINES.BT,
      LINES.PAN,
      LINES.DP,
      LINES.DPX,
      LINES.HBB,
      LINES.HO,
      LINES.HOA,
      LINES.HOBP,
      LINES.HOBX,
      LINES.HOX,
      LINES.MH,
      LINES.MHA,
      LINES.PAU,
      LINES.PPL,
      LINES.PCY,
      LINES.PIM,
      LINES.PUP,
      LINES.YT,
    ],
    color: "#8a2532",
  },
};

export default LINE_CATEGORIES;
