/**
 * @description This is the User Screen module that is used
 * to serve the User Screen View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/UserScreen
 */

import React, { useState, useEffect } from "react";
import * as UserApi from "../apis/UserApi";
import SummaryBar from "../components/SummaryBar";
import { getDisplayName } from "../util/userUtil";
import UserCard from "../components/UserCard";
import UserInfo from "../components/UserInfo";
import UserTabsSection from "../components/UserTabsSection";
import ReloadContext from "../contexts/ReloadContext";
import OrgsTable from "../components/OrgsTable";
import Bulletins from "../components/Bulletins";
import { useParams } from "react-router-dom";

export default function UserScreen(props) {
  const [toggleToRerender, setToggleToRerender] = useState(true);

  const { userId } = useParams();

  const [user, setUser] = useState(UserApi.getFromCache(userId));

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "User Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, [userId]);

  useEffect(() => {
    UserApi.get(userId).then(user => {
      setUser(user);
    });
  }, [userId, toggleToRerender]);

  /**
   * @description Used to refetch the data for the screen
   * @since 1.0.0
   * @author Findlay Clarke <findlayc@aaisonline.com>
   * @inner
   */
  function updateScreen() {
    setToggleToRerender(!toggleToRerender);
  }

  const displayName = getDisplayName(user);

  return (
    <React.Fragment>
      <ReloadContext.Provider value={updateScreen}>
        <SummaryBar expanded={false} title={displayName}>
          <UserCard user={user} edit />
        </SummaryBar>
        <UserTabsSection
          infoTab={<UserInfo user={user} />}
          orgsTab={<OrgsTable orgIds={user.orgs} userId={user.userId} />}
          bulletinsTab={<Bulletins user={user} />}
        />
      </ReloadContext.Provider>
    </React.Fragment>
  );
}
