/**
 * @description The enumeration of the Package types
 * @since 1.0.0
 * @enum {String}
 * @readonly
 * @public
 */
const PACKAGE_TYPES = {
  /**
   * @constant {String}
   * @description The mailing address
   * @since 1.0.0
   */
  FORMS: {
    key: "FORMS",
    title: "Forms",
    description: "The forms",
    packageType_s: "PFM",
  },
  /**
   * @constant {String}
   * @description The loss cost
   * @since 1.0.0
   */
  LOSS_COST: {
    key: "LOSS_COST",
    title: "Loss Cost",
    description: "The Loss Cost",
    packageType_s: "PLC",
  },
  /**
   * @constant {String}
   * @description The rules
   * @since 1.0.0
   */
  RULES: {
    key: "RULES",
    title: "Rules",
    description: "The rules",
    packageType_s: "PRL",
  },
  /**
   * @constant {String}
   * @description The rating info
   * @since 1.0.0
   */
  RATING_INFO: {
    key: "RATING_INFO",
    title: "Rating Info",
    description: "The Rating Info",
    packageType_s: "PRI",
  },
  SUPPORTING_DOCS: {
    key: "SUPPORTING_DOCS",
    title: "Supporting Documents",
    description: "The supporting documents",
    packageType_s: "SUP",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      ADVISORY_INFO: {
        key: "ADVISORY_INFO",
        title: "Advisory Information",
        description: "",
        documentType_s_query: "ADV",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      CAUSE_OF_LOSS_REPORT: {
        key: "CAUSE_OF_LOSS_REPORT",
        title: "Cause of Loss Report",
        description: "",
        documentType_s_query: "COL",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      SIDE_BY_SIDE: {
        key: "SIDE_BY_SIDE",
        title: "Comparison or Side-By-Side",
        description: "",
        documentType_s_query: "SBS",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      HEAT_MAPS: {
        key: "HEAT_MAPS",
        title: "Heat Maps",
        description: "",
        documentType_s_query: "MAP",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      RATING_EXAMPLES: {
        key: "RATING_EXAMPLES",
        title: "Rating Examples",
        description: "",
        documentType_s_query: "RTX",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      STATUS_REPORTS: {
        key: "STATUS_REPORTS",
        title: "Status Reports",
        description: "",
        documentType_s_query: "REP",
      },
    },
  },
  SUPPORTING_DOCS_IMG: {
    key: "SUPPORTING_DOCS_IMG",
    title: "Supporting Documents (IMG)",
    description: "The supporting documents for Inland Marine Guide",
    packageType_s: "IMS",
    types: {
      /**
       * @constant {object}
       * @since 1.0.0
       */
      CLASS_INFORMATION: {
        key: "CLASS_INFORMATION",
        title: "Class Information",
        description: "",
        documentType_s_query: "CIN",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      COMPATIBLE_SCHEDULES_AND_ENDORSEMENTS: {
        key: "COMPATIBLE_SCHEDULES_AND_ENDORSEMENTS",
        title: "Compatible Shedules and Endorsements",
        description: "",
        documentType_s_query: "CSE",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      COVERAGE_COMMENTARY: {
        key: "COVERAGE_COMMENTARY",
        title: "Coverage Commentary",
        description: "",
        documentType_s_query: "CVC",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      FILING_INFORMATION: {
        key: "FILING_INFORMATION",
        title: "Filing Information",
        description: "",
        documentType_s_query: "FIN",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      GENERAL_INFORMATION: {
        key: "GENERAL_INFORMATION",
        title: "General Information",
        description: "",
        documentType_s_query: "GIN",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      RATING_WORKSHEETS: {
        key: "RATING_WORKSHEETS",
        title: "Rating Worksheets",
        description: "",
        documentType_s_query: "RTW",
      },
      /**
       * @constant {object}
       * @since 1.0.0
       */
      UNDERWRITING: {
        key: "UNDERWRITING",
        title: "Underwriting",
        description: "",
        documentType_s_query: "UND",
      },
    },
  },
};

export default PACKAGE_TYPES;
