/**
 * @description This is the Screen Not Found Screen module that is used
 * to serve the 404 View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/ScreenNotFoundScreen
 */
import React from "react";

export default class ScreenNotFoundScreen extends React.Component {
  render() {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "404 Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
    return <h1>Error code 404: Page not found</h1>;
  }
}
