/**
 * @description This is the Risk awareness Screen
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/RiskAwarenessScreen
 */
import React from "react";
import Container from "@material-ui/core/Container";
import SummaryBar from "../components/SummaryBar";
import GenericRiskCard from "../components/GenericRiskCard";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

export default function RiskAwarenessScreen(props) {
  const classes = useStyles();

  // render() {
  //   window.gtag("config", `${process.env.REACT_APP_GA}}`, {
  //     page_title: "Risk awareness screen",
  //     page_path: `${window.location.pathname}${window.location.hash}`,
  //     path_location: window.location.href,
  //   });
  return (
    <React.Fragment>
      <SummaryBar title="Risk Awareness"></SummaryBar>
      <Container className={classes.container} maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
            <GenericRiskCard
              title="AAIS Hurricane Live Event Tracker"
              src="https://aais.maps.arcgis.com/apps/webappviewer/index.html?id=1c17347d0c4a4d649d56ce8601db876e"
            ></GenericRiskCard>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
            <GenericRiskCard
              title="AAIS Catastrophe Planning: Hurricanes"
              src="https://storymaps.arcgis.com/stories/777b332067c649a6a5e5b2c8b85845c7"
            ></GenericRiskCard>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
}));
