import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import BusinessIcon from "@material-ui/icons/Business";
import AlertIcon from "@material-ui/icons/AddAlert";
import { useLocation, useParams, useHistory } from "react-router-dom";

export default function UserTabsSection(props) {
  const { infoTab, orgsTab, bulletinsTab } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { userId } = useParams();
  let initial = 0;
  if (location.pathname.endsWith("bulletins")) {
    initial = 1;
  }

  const [selectedTab, setTab] = useState(initial);

  function handleChange(event, newSelectedTab) {
    if (selectedTab === 0) {
      //FIXME: not sure why this works
      history.replace(`/users/${userId}/bulletins`);
    } else {
      history.replace(`/users/${userId}`);
    }
    setTab(newSelectedTab);
  }

  return (
    <React.Fragment>
      <Paper className={classes.root} elevation={0}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered={true}
        >
          <Tab label="Info" icon={<ContactMailIcon />} />
          {/* <Tab label="Associated Orgs" icon={<BusinessIcon />} /> */}
          <Tab label="Notification Preferences" icon={<AlertIcon />} />
        </Tabs>
      </Paper>
      {selectedTab === 0 && <React.Fragment>{infoTab}</React.Fragment>}
      {/* {selectedTab === 1 && <React.Fragment>{orgsTab}</React.Fragment>} */}
      {selectedTab === 1 && <React.Fragment>{bulletinsTab}</React.Fragment>}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
  },
  tab: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: "red",
  },
  topSection: {
    marginBottom: theme.spacing(2),
  },
}));
