import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getRoleByKey } from "../enums/ROLES";
import { Link } from "react-router-dom";
import { getOrgLink } from "../util/orgUtil";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import * as commonUtil from "../util/commonUtil";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
});

function OrgsTable(props) {
  const { classes, orgs = [], me } = props;

  function getTableRow(org) {
    if (!org) return null;

    let role = null;

    if (org.users && me && me.userId) {
      const orgMe = org.users.find((aUser) => aUser.userId === me.userId);

      //there is no org user then check for super access
      if (orgMe && orgMe.roleKey) {
        role = getRoleByKey(orgMe.roleKey);
      }
    }

    if (!role) {
      //still cant find role, check app wide
      if (me && me.apps) {
        const app = me.apps.find((app) => app.appName === "mss");
        if (app && app.roleKey) {
          role = getRoleByKey(app.roleKey);
        }
      }
    }

    return (
      <React.Fragment key={`${org.orgId}-section`}>
        <TableRow key={org.orgId} hover={true}>
          <TableCell component={Link} to={getOrgLink(org.orgId)}>
            <Typography>{org.name}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{org.users ? org.users.length : 0}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{role ? role.title : "N/A"}</Typography>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  function getRoleColumnName() {
    if (me && me.firstName) {
      return `${me.firstName}'s Role`;
    }
    return "Role";
  }

  return (
    <React.Fragment>
      {orgs && (
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Organization Name</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Users</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{getRoleColumnName()}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orgs.map((org) => {
                  return getTableRow(org);
                })}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      )}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(OrgsTable);
