import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SummaryBar from "./SummaryBar";
import LINE_CATEGORIES from "../enums/LINES_CATEGORIES";
import { Paper, Link } from "@material-ui/core";


function LineMenu(props) {
  const { classes } = props;

  function getLine(lineCategory, line) {
    return (
      <Typography key={line.key}>
        <Link
          href={`#/lines/${line.key}`}
          data-aws-pp-on="click"
          data-aws-pp-name="click"
          data-aws-pp-attrs={`line:${line.key},comp:LineMenu`}
        >
          {line.title}
        </Link>
      </Typography>
    );
  }

  function getLineCategory(lineCategory) {
    const lines = lineCategory.lines.map((line) => {
      if (line.notVisible) return null;
      return getLine(lineCategory, line);
    });

    return (
      <Grid item xs={12} sm={6} md={3} key={lineCategory.key}>
        <SummaryBar
          title={lineCategory.title.toUpperCase()}
          bgColor={lineCategory.color}
          expanded={false}
          key={`sb-${lineCategory.key}`}
          hideExpand={true}
        >
          <div className={classes.lineCategory}>
            <Paper className={classes.categoryList}>{lines}</Paper>
          </div>
        </SummaryBar>
      </Grid>
    );
  }

  function getCategories() {
    const sections = Object.keys(LINE_CATEGORIES)
      .sort()
      .map((lineCategoryName) => {
        const lineCategory = LINE_CATEGORIES[lineCategoryName];
        return getLineCategory(lineCategory);
      });
    return sections;
  }

  return (
    <div className={classes.root}>
      <Grid container>{getCategories()}</Grid>
    </div>
  );
}
const styles = (theme) => ({
  categoryList: {
    padding: 10,
  },
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
  lineCategory: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
});

export default withStyles(styles, { withTheme: true })(LineMenu);
