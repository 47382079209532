import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MeContext from "../contexts/MeContext";
import Typography from "@material-ui/core/Typography";
import {
  Container,
  Paper,
  Button,
  LinearProgress,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import * as AssetApis from "../apis/AssetApi";
import STATES from "../enums/STATES";
import LINES from "../enums/LINES";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";

export default function LivrariaScreen() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [compareResults, setCompareResults] = useState([]);
  const [states] = useState(
    Object.keys(STATES).map(state => STATES[state].key)
  );
  const [lines] = useState(Object.keys(LINES));
  const [packageTypes] = useState(["FORMS"] /*Object.keys(PACKAGE_TYPES)*/);
  const me = useContext(MeContext);

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Livraria Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, []);

  useEffect(() => {
    if (compareResults.length > 0 && compareResults.length < lines.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [compareResults.length, lines.length]);

  async function syncClicked() {
    await AssetApis.syncWithLivraria();
    alert("Sync request sent. You will get an email when sync has completed");
  }

  function compareWithProdClick() {
    setLoading(true);
    setCompareResults([]);
    const persist = false;
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      Promise.all([
        AssetApis.getManifestProdV1(line, states, packageTypes),
        AssetApis.createManifestV1(line, states, packageTypes, persist),
      ]).then(
        data => {
          if (data[0] && data[1]) {
            const prodFiles = new Set(
              data[0].files.map(f => `${f.path}/${f.name}`)
            );
            const nonProdFiles = new Set(
              data[1].files.map(f => `${f.path}/${f.name}`)
            );

            const added = [...nonProdFiles].filter(x => !prodFiles.has(x));
            const removed = [...prodFiles].filter(x => !nonProdFiles.has(x));

            setCompareResults(r => {
              return r.concat([{ line, added: added, removed: removed }]);
            });
          } else {
            setCompareResults(r => {
              return r.concat([{ line, added: [], removed: [] }]);
            });
          }
        },
        error => {
          console.log("some error occured", error);
        }
      );
    }
  }

  return (
    <React.Fragment>
      {loading && (
        <LinearProgress
          variant="buffer"
          value={(compareResults.length * 100) / lines.length + 10}
          valueBuffer={(compareResults.length * 100) / lines.length + 20}
        ></LinearProgress>
      )}
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Typography variant="h5">Livraria Sync</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              disabled={loading}
              onClick={syncClicked}
            >
              Request Latest from Livraria
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={compareWithProdClick}
              disabled={loading}
              data-test="compareWithProd"
            >
              {loading && <CircularProgress size={12} />}
              Compare with Production
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {compareResults.map(result =>
            result.added.length > 0 || result.removed.length > 0 ? (
              <Grid
                container
                item
                key={result.line}
                className={classes.product}
              >
                <Grid item xs={12} className={classes.title}>
                  <Typography variant="h5" display="inline">
                    {`${LINES[result.line].title} (${result.line}) `}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    (
                  </Typography>
                  <Typography
                    variant="h5"
                    display="inline"
                    className={classes.green}
                  >
                    ++{result.added.length}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    /
                  </Typography>
                  <Typography
                    variant="h5"
                    display="inline"
                    className={classes.red}
                  >
                    --{result.removed.length}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    )
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  {result.added.map(added => (
                    <Typography key={added} className={classes.green}>
                      {added}
                    </Typography>
                  ))}
                </Grid>
                <Grid item xs={12} md={6}>
                  {result.removed.map(removed => (
                    <Typography key={removed} className={classes.red}>
                      {removed}
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            ) : null
          )}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  green: {
    color: "green",
    paddingLeft: 10,
    paddingRight: 10,
  },
  red: {
    color: "red",
    paddingLeft: 10,
    paddingRight: 10,
  },
  product: {
    border: "1px solid #dedede",
    marginTop: 10,
    marginBottom: 10,
    paddingTop: "0 !important",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  title: {
    backgroundColor: "#d7ecff",
    padding: 10,
  },
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  lineDescription: {
    padding: theme.spacing(4),
  },
}));
