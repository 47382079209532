import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import * as AssetApi from "../apis/AssetApi";
import * as OrgApi from "../apis/OrgApi";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link } from "@material-ui/core";
import LINES from "../enums/LINES";

export default function MyOrgsCard(props) {
  const classes = useStyles();
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrgs();
  }, []);

  async function getOrgs() {
    const data = await OrgApi.getAll();
    setOrgs(data);
    setLoading(false);
  }

  function getList() {
    return orgs?.map((org) => (
      <Typography key={org.orgId}>
        <Link href={`#/orgs/${org.orgId}`}>
          {`${org.name}`}
        </Link>
      </Typography>
    ));
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader className={classes.cardHeader} title={`My Orgs`} />
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              {!loading && orgs?.length === 0 && (
                <Typography>No associated Orgs</Typography>
              )}
              {!loading && orgs?.length !== 0 && getList()}
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
