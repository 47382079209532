/**
 * @description This is the Member Data Submission Screen
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/MemberDataSumissions
 */
import React, { useState, useEffect, useRef } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Paper, Typography } from "@material-ui/core";
import * as MdsApi from "../apis/MdsApi";
import { v4 as uuid } from "uuid";
import LinearProgress from "@material-ui/core/LinearProgress";
import BucketList from "../components/BucketList";
import GenericSingleTextDialog from "../components/GenericSingleTextDialog";
import { Redirect } from "react-router-dom";

export default function BucketsScreen(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [buckets, setBuckets] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [bucketId, setBucketId] = useState();

  useEffect(() => {
    loadBuckets();
  }, []);

  async function loadBuckets() {
    setLoading(true);
    const theBuckets = await MdsApi.listBuckets();
    setBuckets(theBuckets);
    setLoading(false);
  }

  async function createBucket(name) {
    setLoading(true);
    const newBucket = await MdsApi.createBucket(name);
    setIsOpen(false);
    setBucketId(newBucket.bucketId);
    setLoading(false);
  }

  const uploadRef = useRef();

  function uploadFileClicked() {
    uploadRef.current.click();
  }

  async function handleFileChanged(e) {
    setLoading(true);

    const file = uploadRef.current.files[0];

    //reset the file so that you can upload the same file again
    e.target.value = null;

    const data = await MdsApi.getPresignedURL(uuid(), file.name);

    const formData = new FormData();
    formData.append("Content-Type", file.type);
    Object.entries(data.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });

    formData.append("file", file); //must be the last one

    try {
      //upload the file
      await fetch(data.url, { method: "POST", body: formData });
    } catch (error) {
      console.error("error uploading file", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <React.Fragment>
      <Container className={classes.container}>
        {loading && <LinearProgress />}
        <Paper className={classes.paper} elevation={2}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5">
                GT2 Buckets {buckets ? `(${buckets?.totalNumberOfItems})` : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsOpen(true)}
              >
                Create Bucket
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <BucketList buckets={buckets?.items ? buckets.items : []}></BucketList>
      </Container>
      {isOpen && (
        <GenericSingleTextDialog
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          title="Bucket Name"
          label="Bucket Name"
          placeholder="2020-Q4 or 2020-01"
          close={() => setIsOpen(false)}
          submitButtonText="Create Bucket"
          onSubmit={createBucket}
        ></GenericSingleTextDialog>
      )}
      {bucketId && <Redirect push to={`/data/buckets/${bucketId}`} />}
      <input
        type="file"
        hidden
        ref={uploadRef}
        onChange={handleFileChanged}
        accept=".txt"
      ></input>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
