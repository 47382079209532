import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";

export default function StatisticalReportingCard(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`Statistical Reporting`}
          />
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              <Typography variant="h5" align="center">
                <Link href={`#/data`}>Statistical Reporting (BETA)</Link>
              </Typography>
            </Paper>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
