import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as AssetApi from "../apis/AssetApi";
import {
  LinearProgress,
  Container,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  ListItemSecondaryAction,
  Button,
} from "@material-ui/core";
import FormIcon from "@material-ui/icons/Description";
import AAISPDFViewerDialog from "../components/AAISPDFViewerDialog";
import * as moment from "moment";

export default function LineUpdates(props) {
  const classes = useStyles();
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewItem, setPreviewItem] = useState();

  useEffect(() => {
    getUpdates();
  }, [props.lineKey]);

  async function getUpdates() {
    setLoading(true);
    const items = await AssetApi.getLineUpdates(props.lineKey);
    setUpdates(items);
    setLoading(false);
  }

  function getUpdateList() {
    if (!updates || updates.length <= 0) return null;

    return (
      <Paper>
        <List>
          {updates.map((u) => {
            let secondaryText = "";
            let icon = null;
            if (u.formEdition_s) {
              secondaryText = `Form Edition: ${u.formEdition_s}. `;
              icon = <FormIcon></FormIcon>;
            } else if (u.manualRevision_s) {
              secondaryText = `Manual Revision: ${u.manualRevision_s}. `;
            }
            if (u.states) {
              secondaryText += `States: ${u.states.join()}`;
            }

            return (
              <ListItem key={u.updateId} divider>
                <ListItemIcon>{icon}</ListItemIcon>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm={8}>
                    <ListItemText
                      primary={`${u.title}`}
                      secondary={secondaryText}
                    ></ListItemText>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ListItemText
                      secondary={`${moment(u.created).format("LLL")}`}
                      primary={moment(u.created).fromNow()}
                    ></ListItemText>
                  </Grid>
                </Grid>
                <ListItemSecondaryAction>
                  <Button
                    edge="end"
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      setPreviewItem(u);
                    }}
                  >
                    Open
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      {loading && <LinearProgress></LinearProgress>}
      <Container maxWidth="xl" className={classes.container}>
        {getUpdateList()}
      </Container>
      {previewItem && (
        <AAISPDFViewerDialog
          s3Key={previewItem.rendition_locations_ss}
          title={previewItem.title}
          handleClose={() => setPreviewItem()}
        ></AAISPDFViewerDialog>
      )}
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));
