/**
 * @description The address card
 * @module components/AddressCard
 */

import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import MeContext from "../contexts/MeContext";
import OrgContext from "../contexts/OrgContext";
import * as userUtil from "../util/userUtil";
import ROLES from "../enums/ROLES";
import Fade from "@material-ui/core/Fade";

function AddressCard(props) {
  const { classes, address } = props;

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  if (!address) return <React.Fragment />;

  function getMultiLineAddress() {
    let streetNumber,
      streetAddress,
      city,
      state,
      zip = "";
    try {
      const addressComponents = JSON.parse(address.addressComponents);
      for (let i = 0; i < addressComponents.length; i++) {
        const component = addressComponents[i];
        if (component.types.includes("street_number")) {
          streetNumber = component.long_name;
        } else if (component.types.includes("route")) {
          streetAddress = component.short_name;
        } else if (component.types.includes("locality")) {
          city = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        } else if (component.types.includes("postal_code")) {
          zip = component.short_name;
        }
      }
    } catch (error) {
      console.error("could not parse address", error);
      return (
        <React.Fragment>
          <Typography className={classes.line2}>
            {address.addressLine2}
          </Typography>
          <Typography className={classes.line2}>
            {address.formattedAddress}
          </Typography>
        </React.Fragment>
      );
    }
    const addressLine1 = `${streetNumber} ${streetAddress}`;
    const addressLine3 = `${city}, ${state}. ${zip}`;
    return (
      <React.Fragment>
        <Typography className={classes.line1}>{addressLine1}</Typography>
        <Typography className={classes.line2}>
          {address.addressLine2}
        </Typography>
        <Typography className={classes.line3}>{addressLine3}</Typography>
      </React.Fragment>
    );
  }

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={3} className={classes.grid}>
        <Fade in={true} timeout={1000}>
          <Card className={classes.card}>
            <CardContent className={classes.map}>
              <iframe
                title={address.formattedAddress}
                width="100%"
                height="150"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?q=place_id:${address.placeId}&key=${apiKey}`}
                allowFullScreen
              />
            </CardContent>
            <CardContent className={classes.addressSection}>
              {getMultiLineAddress()}
            </CardContent>
            <CardActions className={classes.cardActions} data-test={"Action-"+address.formattedAddress}>
              {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
                <React.Fragment>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => props.handleRemove(address.addressId)}
                  >
                    Remove
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => props.onClickUpdate(address)}
                  >
                    Update
                  </Button>
                </React.Fragment>
              )}
            </CardActions>
          </Card>
        </Fade>
      </Grid>
    </React.Fragment>
  );
}

const styles = theme => ({
  grid: {
    flexGrow: 1,
  },
  map: {
    padding: 0,
  },
  line1: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
  },
  line2: {
    fontSize: theme.typography.pxToRem(16),
  },
  line3: {
    fontSize: theme.typography.pxToRem(16),
  },
  formattedAddress: {
    fontSize: theme.typography.pxToRem(16),
  },
  addressSection: {
    height: 55,
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  title: {
    fontSize: 14,
  },
});

export default withStyles(styles, { withTheme: true })(AddressCard);
