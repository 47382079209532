/**
 * @description This is the Org Screen module that is used
 * to serve the Org Screen View
 * @author Findlay Clarke <findlayc@aaisonline.com>
 * @since 1.0.0
 * @module screens/OrgScreen
 */

import React, { useState, useReducer, useEffect } from "react";
import SummaryBar from "../components/SummaryBar";
import UsersTable from "../components/UsersTable";
import { withStyles } from "@material-ui/core/styles";
import * as OrgApi from "../apis/OrgApi";
import * as orgUtil from "../util/orgUtil";
import ADDRESS_TYPES from "../enums/ADDRESS_TYPES";
import CONTACT_TYPES from "../enums/CONTACT_TYPES";
import UserCard from "../components/UserCard";
import ContactSection from "../components/ContactSection";
import AddressSection from "../components/AddressSection";
import Transactions from "../components/Transactions";
import OrgTabsSection from "../components/OrgTabsSection";
import ReloadContext from "../contexts/ReloadContext";
import OrgContext from "../contexts/OrgContext";
import ROLES from "../enums/ROLES";
import RemoveUserConfirmation from "../components/RemoveUserConfirmation";

/**
 * @namespace OrgScreen
 * @description The functional component for the Org Screen
 * @param {*} props
 * @example
 * <OrgScreen></OrgScreen>
 *
 */
function OrgScreen(props) {
  const orgId = props.match.params.orgId;
  const [toggleToRerender, setToggleToRerender] = useState(true);
  const [org, setOrg] = useState(null);
  const [showConfirmDeleteUser, setShowConfirmDeleteUser] = useState(false);
  const [userToRemove, setUserToRemove] = useState();
  const [deleteAudit, setDeleteAudit] = useState();

  useEffect(() => {
    OrgApi.get(orgId).then((org) => {
      setOrg(org);
    });
  }, [orgId, toggleToRerender]);

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Org Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, [orgId]);

  function reducer(state, action) {
    switch (action.type) {
      case "reload": {
        updateScreen();
        return toggleToRerender;
      }
      default:
        return toggleToRerender;
    }
  }

  // eslint-disable-next-line no-unused-vars
  const [state, dispatch] = useReducer(reducer);

  /**
   * @description Used to refetch the data for the screen
   * @since 1.0.0
   * @author Findlay Clarke <findlayc@aaisonline.com>
   * @inner
   */
  function updateScreen() {
    setToggleToRerender(!toggleToRerender);
  }

  const adminUsersCards = getAdminUsersCards(org);

  /**
   * @inner
   * @description the handler to add a contact when a contact has been
   * added to a contact section
   * @since 1.0.0
   * @public
   * @async
   */
  async function handleAddContact(user, contactType) {
    await OrgApi.addContact(user.userId, orgId, contactType);
    updateScreen();
  }

  /**
   * @inner
   * @description the handler to remove a contact when a contact has been
   * added to a contact section
   * @since 1.0.0
   * @public
   * @async
   */
  async function handleRemoveContact(user, contactType) {
    await OrgApi.removeContact(user.userId, orgId, contactType);
    updateScreen();
  }

  async function handleRoleChange(user, role) {
    await OrgApi.updateUser(user.userId, orgId, role);
    updateScreen();
  }

  function handleClose() {
    setDeleteAudit(null);
    setUserToRemove(null);
    setShowConfirmDeleteUser(false);
  }

  async function handleDeleteUserAudit(user) {
    const audit = await OrgApi.removeUserAudit(user.userId, orgId);
    setDeleteAudit(audit);
    setUserToRemove(user);
    setShowConfirmDeleteUser(true);
  }

  async function handleDeleteUser(user) {
    await OrgApi.removeUser(user.userId, orgId);
    setShowConfirmDeleteUser(false);
    setDeleteAudit(null);
    setUserToRemove(null);
    updateScreen();
  }

  async function handleAddUser(email) {
    await OrgApi.addUser(email, orgId);
    updateScreen();
  }

  function handleCreateUser(email, contactType) {
    alert(
      `${JSON.stringify(email)} user created and added to ${contactType.key}`
    );
  }
  async function handleAddAddress(
    placeId,
    formattedAddress,
    addressComponents,
    addressLine2,
    addressType
  ) {
    await OrgApi.addAddress(
      orgId,
      addressType,
      placeId,
      formattedAddress,
      addressLine2,
      addressComponents
    );
    updateScreen();
  }

  async function handleUpdateAddress(
    placeId,
    formattedAddress,
    addressComponents,
    addressLine2,
    addressId,
    addressType
  ) {
    await OrgApi.updateAddress(
      orgId,
      addressType,
      placeId,
      formattedAddress,
      addressLine2,
      addressComponents,
      addressId
    );
    updateScreen();
  }

  async function handleRemoveAddress(addressId, addressType) {
    await OrgApi.deleteAddress(orgId, addressType, addressId);
    updateScreen();
  }

  function getAddresses(org, addressType) {
    if (!org || !org.addresses) return null;

    return org.addresses[addressType.key];
  }

  function getAdminUsersCards(org) {
    if (!org || !org.users) {
      return null;
    }

    const adminUserIds = orgUtil.getOrgUsersIds(org, "admin");
    return adminUserIds.map((userId, index) => {
      return (
        <UserCard
          key={userId}
          userId={userId}
          badgeContent="Admin"
          index={index}
        />
      );
    });
  }

  function getContactsTab(org) {
    const sections = Object.keys(CONTACT_TYPES).map((contactTypeName) => {
      const contactType = CONTACT_TYPES[contactTypeName];
      return (
        <ContactSection
          key={contactTypeName}
          userIds={orgUtil.getContactsUserIds(org, contactType)}
          contactType={contactType}
          handleAddContact={handleAddContact}
          handleRemoveContact={handleRemoveContact}
          handleCreateUser={handleCreateUser}
        />
      );
    });

    return sections;
  }

  function getAddressesTab(org) {
    const sections = Object.keys(ADDRESS_TYPES).map((addressTypeName) => {
      const addressType = ADDRESS_TYPES[addressTypeName];
      return (
        <AddressSection
          key={addressTypeName}
          addresses={getAddresses(org, addressType)}
          addressType={addressType}
          handleAdd={handleAddAddress}
          handleRemove={handleRemoveAddress}
          handleUpdate={handleUpdateAddress}
        />
      );
    });

    return sections;
  }

  return (
    <React.Fragment>
      <ReloadContext.Provider value={dispatch}>
        <OrgContext.Provider value={org}>
          <SummaryBar expanded={true} title={org ? org.name : null}>
            {adminUsersCards}
          </SummaryBar>
          <OrgTabsSection
            contactsTab={getContactsTab(org)}
            usersTab={
              <UsersTable
                userEntries={org ? org.users : []}
                handleRoleChange={handleRoleChange}
                handleRemoveUser={handleDeleteUserAudit}
                handleAddUser={handleAddUser}
              />
            }
            addressesTab={getAddressesTab(org)}
            historyTab={<Transactions orgId={orgId} />}
          />
        </OrgContext.Provider>
      </ReloadContext.Provider>
      {showConfirmDeleteUser && (
        <RemoveUserConfirmation
          orgName={org.name}
          user={userToRemove}
          audit={deleteAudit}
          handleDelete={handleDeleteUser}
          handleClose={handleClose}
        />
      )}
    </React.Fragment>
  );
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
  },
  usersSection: {
    marginTop: 40,
    marginLeft: 20,
    marginRight: 20,
  },
});

export default withStyles(styles, { withTheme: true })(OrgScreen);
