import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as AssetApi from "../apis/AssetApi";

export default function DownloadMaterialsDialog(props) {
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait while we process your request..."
  );
  const [continueProcessing, setContinueProcessing] = useState(true);

  useEffect(() => {
    if (
      !props.manifestId &&
      (!props.lineKey ||
        !Array.isArray(props.states) ||
        props.states.length <= 0 ||
        !Array.isArray(props.packageTypes) ||
        props.packageTypes.length <= 0)
    ) {
      console.error(
        `lineKey [${props.lineKey}] states [${props.states}] packageTypes [${props.packageTypes}] required params not set correctly`
      );
      setLoadingMessage("Could not process your request");
      setContinueProcessing(false);
    }
  }, []);

  useEffect(() => {
    asycDownloadWrapper();
  }, []);

  //analytics
  useEffect(() => {
    if (props.lineKey && props.states && props.packageTypes) {
      window.gtag("event", "package.download.states", {
        event_category: props.lineKey,
        event_label: JSON.stringify(props.states),
        value: props.states.length,
      });
      window.gtag("event", "package.download.packageTypes", {
        event_category: props.lineKey,
        event_label: JSON.stringify(props.packageTypes),
        value: props.packageTypes.length,
      });
    }
  }, []);

  async function asycDownloadWrapper() {
    let manifestId, zipFileName;
    if (continueProcessing) {
      manifestId = props.manifestId ? props.manifestId : await createManifest();
    }

    if (continueProcessing && manifestId) {
      zipFileName = await createZipFile(manifestId);
    }

    if (continueProcessing && zipFileName) {
      downloadFile(zipFileName);
    }
  }

  function handleCancel() {
    setContinueProcessing(false);
    props.handleClose();
  }

  async function createManifest() {
    if (
      !props.lineKey ||
      !Array.isArray(props.states) ||
      props.states.length <= 0 ||
      !Array.isArray(props.packageTypes) ||
      props.packageTypes.length <= 0
    ) {
      return null;
    }

    const manifest = await AssetApi.createManifestV2(
      props.lineKey,
      props.packageTypes,
      props.states,
      props.status_s,
      true,
      props.imgClass_s,
      props.documentType_s_query,
      props.searchTerm
    );
    console.log(manifest);
    if (manifest.files && manifest.files.length === 0) {
      setLoadingMessage("There are no files to be delivered");
      setContinueProcessing(false);
      return;
    }
    return manifest.id;
  }

  async function createZipFile(manifestId) {
    const zipFileName = await AssetApi.createZipFileV1(manifestId);
    console.log(zipFileName);
    return zipFileName;
  }

  function downloadFile(zipFileName) {
    const prefixUrl = process.env.REACT_APP_MSS_PUBLIC_TEMP_CACHE_URL;
    const url = `${prefixUrl}/${zipFileName}`;
    window.open(url, "_parent");
    props.handleClose();
  }

  return (
    <Dialog
      open={true}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">
        Downloading Latest Content
      </DialogTitle>
      <DialogContent>
        {continueProcessing && <LinearProgress />}
        <DialogContentText id="alert-dialog-description">
          {loadingMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
