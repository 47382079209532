import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";

export default function GenericSingleTextDialog(props) {
  const [value, setValue] = useState(props.value ? props.value : "");

  return (
    <Dialog open={true} onClose={props.close} fullWidth maxWidth="sm">
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={props.label}
          placeholder={props.placeholder}
          type="text"
          value={value}
          fullWidth
          required
          onChange={(e) => setValue(e.target.value)}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={() => props.onSubmit(value)}
          variant="contained"
          color="primary"
          disabled={!value || value.length === 0}
        >
          {props.submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
