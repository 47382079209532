import React, { useState, useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import * as userUtil from "../util/userUtil";
import * as UserApi from "../apis/UserApi";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ROLES from "../enums/ROLES";
import { getEnumFromKey } from "../util/commonUtil";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import DeleteIcon from "@material-ui/icons/Delete";
import ResendEmailIcon from "@material-ui/icons/MailOutline";
import IconButton from "@material-ui/core/IconButton";
import MeContext from "../contexts/MeContext";
import OrgContext from "../contexts/OrgContext";
import Snackbar from "@material-ui/core/Snackbar";
import InviteUserDialog from "./InviteUserDialog";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  loadingRow: {
    padding: 0,
    height: 0,
  },
  loadingCell: {
    padding: 0,
  },
  roleKeySelector: {
    minWidth: 125,
  },
});

function UsersTable(props) {
  const { classes, userEntries = [] } = props;

  console.log("Users", userEntries);

  const [loadingUserId, setLoadingUserId] = useState();
  const [loadingGeneric, setLoadingGeneric] = useState(true);
  const userIds = userEntries.map((userEntry) => userEntry.userId);
  const [users, setUsers] = useState(UserApi.getUsersFromCache(userIds));
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [snack, setSnack] = React.useState({ open: false, note: null });

  const me = useContext(MeContext);
  const org = useContext(OrgContext);

  useEffect(() => {
    getUsers();
  }, [userEntries.length]); //eslint-disable-line react-hooks/exhaustive-deps

  async function getUsers() {
    setLoadingGeneric(true);
    const tempUsers = await UserApi.getUsers(userIds);
    setUsers(tempUsers);
    setLoadingGeneric(false);
  }

  function getTableRow(user) {
    if (!user) return null;

    let picture;
    if (user.picture) {
      picture = user.picture;
    }

    const orgUser = userEntries.find((aUser) => aUser.userId === user.userId);

    if (!orgUser || !orgUser.roleKey) return null;

    const role = getEnumFromKey(ROLES, orgUser.roleKey);

    const meString = me.userId === user.userId ? " (me)" : "";

    return (
      <React.Fragment key={`${user.userId}-section`}>
        {loadingUserId === user.userId && (
          <TableRow key={`${user.userId}-load`} className={classes.loadingRow}>
            <TableCell colSpan={4} className={classes.loadingCell}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}
        <TableRow key={user.userId} hover={true} data-test={"rowDisplay-"+user.email}>
          <TableCell component="th" scope="row">
            <Avatar alt={userUtil.getDisplayName(user)} src={picture}>
              {!picture ? userUtil.getInitials(user) : null}
            </Avatar>
          </TableCell>
          <TableCell component={Link} to={userUtil.getProfileLink(user.userId)}>
            <Typography variant="body1">
              {userUtil.getDisplayName(user)}
              {meString}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography data-test="roleChange">{getRoleForm(user, role)}</Typography>
          </TableCell>
          {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
            <TableCell>
              <IconButton
                aria-label="removeIcon"
                data-test="roleDelete"
                onClick={() => handleRemoveUser(user)}
                disabled={
                  //user cant delete admin users unless they are an admin
                  role.key === ROLES.ADMIN.key &&
                  !userUtil.canDoOrg(me, ROLES.ADMIN, org)
                }
              >
                <DeleteIcon />
              </IconButton>
              {!user.termsAccepted && (
                <IconButton
                  color="secondary"
                  aria-label="resendEmail"
                  onClick={() => handleResendInviteUser(user.email)}
                >
                  <ResendEmailIcon />
                </IconButton>
              )}
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  }

  async function handleRoleChange(user, role) {
    setLoadingUserId(user.userId);
    await props.handleRoleChange(user, role);
    setLoadingUserId();
    setSnack({
      open: true,
      note: `${userUtil.getDisplayName(user)} role updated to ${role.title}`,
    });
  }

  async function handleRemoveUser(user) {
    setLoadingUserId(user.userId);
    await props.handleRemoveUser(user);
    setLoadingUserId();
  }

  async function handleAddUser(email) {
    setLoadingGeneric(true);
    setShowAddUserDialog(false);
    await props.handleAddUser(email);
    setLoadingGeneric(false);
    setSnack({
      open: true,
      note: `${email} has been added`,
    });
  }

  async function handleResendInviteUser(email) {
    setLoadingGeneric(true);
    await UserApi.resendInvite(email);
    setLoadingGeneric(false);
    setSnack({
      open: true,
      note: `Invite has been sent to ${email}`,
    });
  }

  function getRoleForm(user, role) {
    const meRO = userUtil.canDoOrg(me, ROLES.READ_ONLY, org);
    const meRW = userUtil.canDoOrg(me, ROLES.READ_WRITE, org);
    const meADMIN = userUtil.canDoOrg(me, ROLES.ADMIN, org);
    const enabled = userUtil.canDoOrg(me, role, org);

    return (
      <FormControl
        variant="outlined"
        //disabled if not enabled or only have RO role
        disabled={!enabled || (meRO && !meRW && !meADMIN)}
      >
        <Select
          value={role}
          onChange={(event) => handleRoleChange(user, event.target.value)}
          input={
            <OutlinedInput labelWidth={0} className={classes.roleKeySelector} />
          }
        >
          {((enabled && meADMIN) || !enabled) && (
            //if its enabled and me is a admin show the option
            <MenuItem data-test={ROLES.ADMIN.title} value={ROLES.ADMIN}>{ROLES.ADMIN.title}</MenuItem>
          )}
          {((enabled && meRW) || !enabled) && (
            //if its enabled and me is RW show the option
            <MenuItem data-test={ROLES.READ_WRITE.title} value={ROLES.READ_WRITE}>
              {ROLES.READ_WRITE.title}
            </MenuItem>
          )}

          <MenuItem data-test={ROLES.READ_ONLY.title} value={ROLES.READ_ONLY}>{ROLES.READ_ONLY.title}</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <React.Fragment>
      {
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={snack.open}
          onClose={() => setSnack({ open: false, note: null })}
          autoHideDuration={5000}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span>{snack.note}</span>}
        />
      }
      <AppBar position="static" color="default">
        <Toolbar>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                variant="h5"
                component="h4"
                data-testid="usersCountLabel" // FIXME: Deprecate data-testid
                data-test="usersCountLabel"
                className={classes.grow}
              >
                Users ({userEntries.length})
              </Typography>
            </Grid>
            <Grid item>
              {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
                <Fab
                  data-testid="addUserButton" // FIXME: Deprecate data-testid 
                  data-test="addUserButton"
                  size="small"
                  color="primary"
                  aria-label="Add"
                  onClick={() => setShowAddUserDialog(true)}
                >
                  <AddIcon />
                </Fab>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loadingGeneric && <LinearProgress data-testid="progressBar" />}
      {users && (
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Avatar</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography>Role</Typography>
                </TableCell>
                {userUtil.canDoOrg(me, ROLES.READ_WRITE, org) && (
                  <TableCell>
                    <Typography>Actions</Typography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return getTableRow(user);
              })}
            </TableBody>
          </Table>
        </Paper>
      )}
      {showAddUserDialog && (
        <InviteUserDialog
          handleClose={() => setShowAddUserDialog(false)}
          handleCreate={handleAddUser}
          addText="Add"
        />
      )}
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(UsersTable);
