import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import * as AssetApi from "../apis/AssetApi";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { LinearProgress } from "@material-ui/core";

export default function AAISPDFViewerDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState();

  useEffect(() => {
    init();
  }, [props.s3Key]);

  async function init() {
    console.log(props);
    const response = await AssetApi.getSignedUrlV1(props.s3Key);
    const encodedUrl = encodeURIComponent(response.url);
    setUrl(encodedUrl);
    setLoading(false);
  }

  return (
    <Dialog open={true} onClose={props.handleClose} maxWidth="xl" fullWidth>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent className={classes.content}>
        {loading && <LinearProgress />}
        {url && (
          <iframe
            src={`https://mozilla.github.io/pdf.js/web/viewer.html?file=${url}#zoom=auto`}
            height="98%"
            title={props.title}
            width="100%"
          ></iframe>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    height: "100vh",
    width: "auto",
  },
}));
