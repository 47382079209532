import React, { useEffect, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import SummaryBar from "../components/SummaryBar";
import AllUsersTable from "../components/AllUsersTable";
import MeContext from "../contexts/MeContext";
import * as userUtil from "../util/userUtil";
import { Redirect } from "react-router-dom";

function UsersScreen(props) {
  const me = useContext(MeContext);

  useEffect(() => {
    window.gtag("config", `${process.env.REACT_APP_GA}}`, {
      page_title: "Org Screen",
      page_path: `${window.location.pathname}${window.location.hash}`,
      path_location: window.location.href,
    });
  }, []);

  if (me && me.email && !userUtil.isEmployee(me)) {
    return <Redirect to={{ pathname: "/403" }} />;
  }

  return (
    <React.Fragment>
      <SummaryBar expanded={false} title={"All Users"} />
      <AllUsersTable />
    </React.Fragment>
  );
}

const styles = (theme) => ({
  grid: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  left: {
    paddingRight: 10,
  },
  right: {
    paddingLeft: 10,
  },
});

export default withStyles(styles, { withTheme: true })(UsersScreen);
