import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as moment from "moment";

export default function BucketList(props) {
  const classes = useStyles();

  if (!props?.buckets || props?.buckets?.length <= 0) return null;

  return (
    <List className={classes.root}>
      {props.buckets.map((bucket) => {
        const dataTestValue = `buckets-listitem-${bucket.name}`;
        return (
          <ListItem
            key={bucket.bucketId}
            divider
            ContainerProps={{ "data-test": dataTestValue }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={6}>
                <ListItemText
                  primary={`${bucket.name}`}
                  // primaryTypographyProps={{ className: unreadTextClassName }}
                  secondary={`${bucket.bucketId}`}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ListItemText
                  // primaryTypographyProps={{ className: unreadTextClassName }}
                  secondary={`${moment(bucket.createdDate).format("LLL")}`}
                  primary={moment(bucket.createdDate).fromNow()}
                ></ListItemText>
              </Grid>
            </Grid>
            <ListItemSecondaryAction>
              <Button
                edge="end"
                color="primary"
                variant="outlined"
                href={`#/data/buckets/${bucket.bucketId}`}
              >
                Open
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  unreadListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  unreadText: {
    fontWeight: "bold",
  },
}));
