import React, { useContext, useState, useEffect, useRef } from "react";
import { Auth } from "aws-amplify";
import { AppBar, Toolbar, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import * as UserApi from "../apis/UserApi";
import MeContext from "../contexts/MeContext";
import * as userUtil from "../util/userUtil";
import Avatar from "@material-ui/core/Avatar";
import ROLES from "../enums/ROLES";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationsIcon from "@material-ui/icons/NotificationsNone";
import MyProfileIcon from "@material-ui/icons/AccountCircle";
import OrgIcon from "@material-ui/icons/Business";
import UsersIcon from "@material-ui/icons/PeopleAlt";
import SignOutIcon from "@material-ui/icons/ExitToApp";
import usePageVisibility from "../hooks/usePageVisibility";
import EmployeeDashboardIcon from "@material-ui/icons/HomeWork";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import uuid from "uuid/v4";

const DEFAULT_TIMEOUT = 1000 * 60;

export default function NavigationBar(props) {
  const classes = useStyles();
  const me = useContext(MeContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [randomNumber, setRandomNumber] = useState(Math.random());
  const timeout = useRef(DEFAULT_TIMEOUT);
  const visible = usePageVisibility();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    checkForNotifications();
  }, []);

  //exponential backoff to check for notifications
  useEffect(() => {
    const timer = setTimeout(() => {
      timeout.current = timeout.current * 2;
      setRandomNumber(Math.random());
      checkForNotifications();
    }, timeout.current);

    return () => clearTimeout(timer);
  }, [randomNumber]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //if the page isnt visible just wait and poll once per day
  useEffect(() => {
    if (visible) {
      timeout.current = DEFAULT_TIMEOUT;
      setRandomNumber(Math.random());
    } else {
      timeout.current = 1000 * 60 * 60 * 24;
      setRandomNumber(Math.random());
    }
  }, [visible]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleSignOut() {
    await Auth.signOut();
  }

  async function checkForNotifications() {
    const tempNotifications = await UserApi.getNotifications();
    setHasNotifications(tempNotifications.length > 0);
  }

  function handlekeyDown(e) {
    if (e.key === "Enter") {
      const prefix = `#/search?sid=${uuid()}&`;
      if (searchValue) window.location = `${prefix}q=${encodeURI(searchValue)}`;
      else window.location = prefix;
      setSearchValue("");
    }
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            component={RouterLink}
            to="/"
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            data-test="AAISlogo"
          >
            <img src="/aais-logo-with-letters-32.png" alt="Home" />
          </IconButton>
          <div className={classes.grow} />
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder="Search"
            data-test="navBarSearch"
            className={classes.search}
            inputProps={{
              onKeyDown: handlekeyDown,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          {me && (
            <React.Fragment>
              <Badge
                color="secondary"
                variant="dot"
                overlap="circle"
                invisible={!hasNotifications}
              >
                <Avatar
                  data-test="navBarAvatar"
                  className={classes.avatar}
                  alt={userUtil.getDisplayName(me)}
                  src={me.picture}
                  onClick={handleClick}
                >
                  {!me.picture ? userUtil.getInitials(me) : null}
                </Avatar>
              </Badge>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                color="primary"
                data-test="navBarMenu"
              >
                <MenuItem
                  component={RouterLink}
                  to="/notifications"
                  onClick={handleClose}
                >
                  <Badge
                    color="secondary"
                    variant="dot"
                    overlap="circle"
                    invisible={!hasNotifications}
                  >
                    <NotificationsIcon></NotificationsIcon>
                  </Badge>
                  <Typography color="primary" className={classes.menuTitle}>
                    Notifications
                  </Typography>
                </MenuItem>
                <MenuItem
                  component={RouterLink}
                  to={userUtil.getProfileLink(me.userId)}
                  onClick={handleClose}
                >
                  <MyProfileIcon></MyProfileIcon>
                  <Typography color="primary" className={classes.menuTitle}>
                    My Profile
                  </Typography>
                </MenuItem>

                <MenuItem
                  component={RouterLink}
                  to="/orgs"
                  onClick={handleClose}
                  hidden={userUtil.canDoApp(me, ROLES.READ_WRITE)}
                >
                  <OrgIcon></OrgIcon>
                  <Typography color="primary" className={classes.menuTitle}>
                    Orgs
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={handleSignOut}
                  divider={userUtil.isEmployee(me)}
                >
                  <SignOutIcon></SignOutIcon>
                  <Typography
                    color="secondary"
                    data-test="signOut"
                    className={classes.menuTitle}
                  >
                    Sign Out
                  </Typography>
                </MenuItem>
                {userUtil.isEmployee(me) && (
                  <MenuItem
                    component={RouterLink}
                    to="/employees"
                    onClick={handleClose}
                  >
                    <EmployeeDashboardIcon color="primary"></EmployeeDashboardIcon>
                    <Typography color="primary" className={classes.menuTitle}>
                      Employees
                    </Typography>
                  </MenuItem>
                )}
                {userUtil.isEmployee(me) && (
                  <MenuItem
                    component={RouterLink}
                    to="/users"
                    onClick={handleClose}
                  >
                    <UsersIcon color="primary"></UsersIcon>
                    <Typography color="primary" className={classes.menuTitle}>
                      All Users
                    </Typography>
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    marginRight: theme.spacing(2),
  },
  menuTitle: {
    marginLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  activeButtonStyle: {
    //backgroundColor: theme.palette.primary.main,
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.primary.main,
    borderRadius: 0,
  },
  activeMeStyle: {
    border: "2px solid",
    borderColor: theme.palette.primary.main,
  },
}));
