import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Paper from "@material-ui/core/Paper";
import DownloadMaterialsDialog from "./DownloadMaterialsDialog";
import * as AssetApi from "../apis/AssetApi";
import PACKAGE_TYPES from "../enums/PACKAGE_TYPES";
import IconButton from "@material-ui/core/IconButton";
import DownloadIcon from "@material-ui/icons/GetApp";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import LinearProgress from "@material-ui/core/LinearProgress";

export default function DownloadHistoryCard(props) {
  const classes = useStyles();
  const [manifestId, setManifestId] = useState();
  const [manifests, setManifests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getHistory();
  }, [props.lineKey]);

  async function getHistory() {
    const data = await AssetApi.getUserManifestMetaData(
      props.userId,
      props.lineKey
    );
    if (Array.isArray(data)) {
      setManifests(data);
    }
    setLoading(false);
  }

  function trackDownload(tempManifestId) {
    window.gtag("event", "package.download.redownload", {
      event_category: props.lineKey,
      event_label: tempManifestId,
      value: 1,
    });
  }

  function getSubheader(packageTypes) {
    if (!packageTypes || !Array.isArray(packageTypes)) {
      return "Custom Package";
    }

    let title = packageTypes.map(
      (packageType) => PACKAGE_TYPES[packageType]?.title
    );
    title = title.sort();
    title = title.join(", ");
    //eslint-disable-next-line no-useless-concat
    title = title.replace(/,([^,]*)$/, " and " + "$1");
    return title;
  }

  function getList() {
    return (
      <List className={classes.list} disablePadding={true}>
        {manifests.map((manifest) => (
          <ListItem
            key={manifest.manifestId}
            divider={true}
            disableGutters={true}
          >
            <ListItemText
              primary={new Date(manifest.created).toLocaleString()}
              secondary={
                <React.Fragment>
                  <Typography>
                    {getSubheader(manifest.request.packageTypes)}
                  </Typography>
                  <Typography>
                    {manifest.request.states.sort().join(", ")}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => {
                  setManifestId(manifest.manifestId);
                  trackDownload(manifest.manifestId);
                }}
                aria-label="download"
              >
                <DownloadIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className={classes.grid}>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={`My Recent ${props.lineKey} Downloads`}
            subheader={`We save your downloads so you don't have to`}
          />
          {loading && <LinearProgress />}
          <CardContent className={classes.cardContent}>
            <Paper className={classes.paper} elevation={0}>
              {!loading && manifests.length === 0 && (
                <Typography>No recent downloads</Typography>
              )}
              {!loading && manifests.length !== 0 && getList()}
            </Paper>
          </CardContent>
        </Card>
        {manifestId && (
          <DownloadMaterialsDialog
            manifestId={manifestId}
            handleClose={() => setManifestId(null)}
          ></DownloadMaterialsDialog>
        )}
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    flexGrow: 1,
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardContent: {
    maxHeight: 280,
    overflowY: "auto",
  },
}));
