import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MeContext from "../contexts/MeContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import * as moment from "moment";
import { Typography } from "@material-ui/core";

export default function NotificationsList(props) {
  const classes = useStyles();
  const [opened, setOpened] = useState([]);
  const me = useContext(MeContext);

  return (
    <Paper>
      {props.notifications.length === 0 && (
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              No new notifications
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={props.reload}>
              Check again
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              href={`#/users/${me.userId}/bulletins`}
            >
              Update Notification Preferences
            </Button>
          </Grid>
        </Grid>
      )}
      <List className={classes.root}>
        {props.notifications.map((n) => {
          const unreadListItemClassName =
            n.unreadSince && !opened.includes(n.notificationId)
              ? classes.unreadListItem
              : null;
          const unreadTextClassName =
            n.unreadSince && !opened.includes(n.notificationId)
              ? classes.unreadText
              : null;
          return (
            <ListItem
              key={n.notificationId}
              divider
              className={unreadListItemClassName}
            >
              <ListItemIcon>
                <Checkbox
                  color="primary"
                  edge="start"
                  checked={
                    props.selectedNotificationIds.indexOf(n.notificationId) !==
                    -1
                  }
                  onChange={(e) =>
                    props.handleChecked(n.notificationId, e.target.checked)
                  }
                />
              </ListItemIcon>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primary={`${n.title}`}
                    primaryTypographyProps={{ className: unreadTextClassName }}
                    secondary={`Lines: ${n.productLines.join()}`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ListItemText
                    primaryTypographyProps={{ className: unreadTextClassName }}
                    secondary={`${moment(n.created).format("LLL")}`}
                    primary={moment(n.created).fromNow()}
                  ></ListItemText>
                </Grid>
              </Grid>

              <ListItemSecondaryAction>
                <Button
                  edge="end"
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    props.handleNotificationClick(n);
                    setOpened(opened.concat([n.notificationId]));
                  }}
                >
                  Open
                </Button>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {},
  unreadListItem: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  unreadText: {
    fontWeight: "bold",
  },
}));
