import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import * as UserApi from "../apis/UserApi";
import { getDisplayName } from "../util/userUtil";
import ReloadContext from "../contexts/ReloadContext";

function UpdateUserDialog(props) {
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const reloadContext = useContext(ReloadContext);

  async function handleUpdate() {
    const updated = await UserApi.updateName(
      props.user.userId,
      firstName,
      lastName
    );
    if (updated) {
      //refresh the page
      reloadContext({ type: "reload" });
      //close the dialog
      props.handleClose();
    }
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Update {getDisplayName(props.user)}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="First Name"
          value={firstName}
          type="text"
          fullWidth
          onChange={event => setFirstName(event.target.value)}
        />
        <TextField
          margin="dense"
          id="name"
          label="Last Name"
          value={lastName}
          type="text"
          fullWidth
          onChange={event => setLastName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpdate} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
});

export default withStyles(styles, { withTheme: true })(UpdateUserDialog);
